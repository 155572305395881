export const rtlCharacterRanges = /[\u0590-\u05FF\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/

export function applyRTL(htmlContent: string): string {
  const parser = new DOMParser()
  const doc = parser.parseFromString(htmlContent, 'text/html')

  const rtlLanguages = ['ar', 'he', 'fa', 'ur']

  function isElementRTL(element: Element): boolean {
    const lang = element.getAttribute('lang')
    return (
      rtlLanguages.some((languageCode) => lang?.startsWith(languageCode)) ||
      rtlCharacterRanges.test(element.textContent || '')
    )
  }

  const isDocumentRTL = isElementRTL(doc.documentElement)
  if (isDocumentRTL) {
    doc.documentElement.setAttribute('dir', 'rtl')
  }

  doc.querySelectorAll('*').forEach((element) => {
    if (isElementRTL(element)) {
      element.setAttribute('dir', 'rtl')
    }
  })

  return new XMLSerializer().serializeToString(doc)
}

export function isRTL(html: string): boolean {
  return applyRTL(html) !== html
}

export function detectRTL(html: string): boolean {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  const rtlLanguages = ['ar', 'he', 'fa', 'ur']

  function isElementRTL(element: Element): boolean {
    const lang = element.getAttribute('lang')
    return (
      rtlLanguages.some((languageCode) => lang?.startsWith(languageCode)) ||
      rtlCharacterRanges.test(element.textContent || '')
    )
  }

  if (isElementRTL(doc.documentElement)) {
    return true
  }

  return Array.from(doc.querySelectorAll('*')).some(isElementRTL)
}
