<script setup lang="ts">
  import { useUserStore } from '@/stores/user'

  const { user } = useUserStore()
</script>

<template>
  <div menu-toggle-ignore class="w-80 py-2 items-center gap-2 flex">
    <img class="w-9 h-9 rounded-full object-contain" v-if="user.avatar" :src="user.avatar" alt="" />
    <img class="w-9 h-9 rounded-full object-contain" v-else :src="user.gravatar + '36'" alt="" />
    <div class="flex flex-col justify-center items-start gap-1 overflow-hidden">
      <div class="self-stretch text-black-10 text-base font-medium leading-tight overflow-hidden text-ellipsis">{{ user.first_name }} {{ user.last_name }}</div>
      <div class="self-stretch text-black-40 text-sm leading-none overflow-hidden text-ellipsis">{{ user.email }}</div>
    </div>
  </div>
</template>
