<script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import { useScroll } from '@vueuse/core'

  const block = ref<HTMLElement>()
  const { arrivedState } = useScroll(block)
  let childWidth = 100

  const onLeftClick = () => {
    if (typeof block.value !== 'undefined') {
      block.value.scrollLeft -= childWidth
    }
  }

  const onRightClick = () => {
    if (typeof block.value !== 'undefined') {
      block.value.scrollLeft += childWidth
    }
  }

  onMounted(() => {
    const secondChild = block.value?.children[1]
    childWidth = (secondChild instanceof HTMLElement && secondChild.offsetLeft) || 100
  })
</script>

<template>
  <div class="relative h-36 pb-4 box-content">
    <div class="h-full relative w-full flex gap-6 overflow-x-auto py-4 -mt-4 scroll-smooth box-content" ref="block">
      <slot></slot>
    </div>

    <div
      class="absolute top-0 bottom-0 right-0 w-24 bg-gradient-to-l from-white to-transparent pointer-events-none"
      :class="!arrivedState.right ? 'block' : 'hidden'"
    ></div>
    <div
      class="absolute top-0 bottom-0 left-0 w-24 bg-gradient-to-r from-white to-transparent pointer-events-none"
      :class="!arrivedState.left ? 'block' : 'hidden'"
    ></div>
    <div
      :class="!arrivedState.left ? 'flex' : 'hidden'"
      class="absolute w-9 h-9 top-1/2 -translate-y-1/2 left-0 bg-white rounded-full border border-black-90 justify-center items-center z-1 cursor-pointer hover:bg-white-gray"
      @click="onLeftClick()"
    >
      <des-icon icon="fa-light fa-chevron-left" />
    </div>
    <div
      :class="!arrivedState.right ? 'flex' : 'hidden'"
      class="absolute w-9 h-9 top-1/2 -translate-y-1/2 right-0 bg-white rounded-full border border-black-90 justify-center items-center z-1 cursor-pointer hover:bg-white-gray"
      @click="onRightClick()"
    >
      <des-icon icon="fa-light fa-chevron-right" />
    </div>
  </div>
</template>
