import { useUserStore } from '@/stores/user'

declare global {
  interface Window {
    Intercom: Function | undefined
    intercomSettings: any | undefined
  }
}

export class IntercomDes {
  static get skipIntercom(): boolean {
    const stored = localStorage.getItem('skipIntercom')
    if (!stored || stored === 'false') {
      return false
    } else if (stored === 'true') {
      return true
    } else {
      try {
        const item = JSON.parse(stored)
        return item?.value === true
      } catch (e) {
        return false
      }
    }
  }
  static trackEvent(name: string, properties: any): void {
    if (window.Intercom !== undefined) {
      window.Intercom('trackEvent', name, properties)
    }
  }
  static setupIntercom(skipIntercom: boolean) {
    const w = window
    const userStore = useUserStore()
    const user = userStore.user
    const APP_ID = 'j624rm1g'

    if (skipIntercom) {
      if (w.Intercom) {
        w.Intercom('shutdown')
      }
      w.intercomSettings = {}
      return
    } else if (!user) {
      if (w.Intercom) {
        w.Intercom('shutdown')
      }
      w.intercomSettings = {}
      return
    } else {
      const { email, created_at } = user
      const userFullName = userStore.getUserFullName(user)
      const formattedCreatedAt = Date.parse(created_at.replace('+', 'T').replace(' ', 'T'))

      w.intercomSettings = {
        name: userFullName,
        email,
        created_at: formattedCreatedAt,
        app_id: APP_ID,
      }
      //if Intercom is already setup
      if (w.Intercom && document.getElementById('intercom-container')) {
        w.Intercom('boot', w.intercomSettings)
        return
      }
    }
    // prettier-ignore
    // @ts-ignore
    // eslint-disable-next-line
    ;(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  }
}
