<script setup lang="ts">
</script>

<template>
  <nav class="flex flex-col p-3 gap-3">
    <slot></slot>
  </nav>
</template>

<style scoped>
</style>
