import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import type { TProjectHistoryEntry } from '@/types/projects'
import { timestamp } from '@vueuse/core'

export const useProjectBackupStore = defineStore('project-backups', () => {

  const indexedDb = ref<IDBDatabase>()
  const localItems = ref<TProjectHistoryEntry[]>([])
  const remoteItems = ref<TProjectHistoryEntry[]>([])

  const entries = computed(() => {
    return localItems.value.concat(remoteItems.value)
  })

  async function openIndexedDb() {
    if (!window.indexedDB) {
        return false
    }

    const request = indexedDB.open("DesignrrProjectBackups", 1)
    request.onerror = function (event: Event) {
      console.log("IndexedDB error")
      console.log(event)
    }

    request.onsuccess = function (event: Event) {
      if (!event.target) {
        return
      }
      const target: IDBOpenDBRequest = event.target as IDBOpenDBRequest
      indexedDb.value = target.result
    }

    request.onupgradeneeded = function (event) {
      if (!event.target) {
        return
      }
      const target: IDBOpenDBRequest = event.target as IDBOpenDBRequest
      indexedDb.value = target.result    
      const objectStore = indexedDb.value.createObjectStore("projects", {autoIncrement: true})

      objectStore.createIndex("timestamp", "timestamp", {unique: true})

      const indexes = ['projectId', 'name', 'html', 'css']
      for (const i in indexes) {
          objectStore.createIndex(indexes[i], indexes[i], {unique: false})
      }      
    }
  }

  async function getLocalEntries(projectId: number) {
    localItems.value = []

    if (!window.indexedDB) {
        return false
    }

    if (!indexedDb.value) {
      await openIndexedDb()
    }

    if (!indexedDb.value) {
      return false
    }    

    const transaction = indexedDb.value.transaction(["projects"], "readwrite")
    const objectStore = transaction.objectStore("projects")
    const index = objectStore.index("projectId")
    const singleKeyRange = IDBKeyRange.only(projectId)

    index.openCursor(singleKeyRange, 'prev').onsuccess = function (event: Event) {
      const target: IDBRequest = event.target as IDBRequest     
      const cursor = target.result
      if (cursor) {
        localItems.value.push(cursor.value)
        localItems.value[localItems.value.length - 1].local = true   
        localItems.value[localItems.value.length - 1].created_at = cursor.value.timestamp.replace(/\//g, "-")
        cursor.continue()
      }
    }
  }

  async function getRemoteEntries(projectId: number) {
    const res = await fetch(`/projects/${projectId}/get-project-history-entries`, {
      method: 'POST',
    })
    if (!res.ok) {
      throw new Error(`Failed to fetch history entries`)
    }
    const data = await res.json()    
    remoteItems.value = data
  }

  return { openIndexedDb, getLocalEntries, getRemoteEntries, entries }

})
