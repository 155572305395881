export enum Products {
  Standard = 1,
  Pro = 2,
  Agency = 3,
  Premium = 201,
  Business = 202,
  AgencyPremium = 203,
  PremiumForAgency = 211,
  Wordgenie = 800,
  AudioImport = 300,
  TextToSpeech = 700,
}

const labels: Record<number, string> = {
  [Products.Standard]: 'Standard',
  [Products.Pro]: 'PRO',
  [Products.Agency]: 'Agency',
  [Products.Premium]: 'Premium',
  [Products.Business]: 'Business',
  [Products.AgencyPremium]: 'Agency Premium',
  [Products.PremiumForAgency]: 'Premium For Agency',
}

export function getLabel(accountTypeId: number): string {
  return labels[accountTypeId] ?? 'Unknown'
}

const icons: Record<number, string> = {
  [Products.Standard]: 'fa-bolt',
  [Products.Pro]: 'fa-star',
  [Products.Premium]: 'fa-crown',
  [Products.AudioImport]: 'fa-circle-play',
  [Products.TextToSpeech]: 'fa-headphones',
}

export function getIcon(accountTypeId: number): string {
  return icons[accountTypeId] ?? 'fa-briefcase'
}
