import { defineStore } from 'pinia'
import { http } from '@/helpers/http'
import { useLoaderStore } from '@/stores/loader'
import { useSnackbarStore } from '@/stores/snackbar'
import type { TLiveEbookDetails, TPublishMetadata } from '@/types/live-ebook'

export const useLiveEbookStore = defineStore('live-ebook', () => {
  const loader = useLoaderStore()
  const snack = useSnackbarStore()

  function validateGAID (id: string) {
    return /^(UA|YT|MO)-\d+-\d+$/i.test(id);
  }

  async function saveDetails (params: TLiveEbookDetails): Promise<{ url: string } | null> {
    loader.show('Saving details')
    const result: { url: string } | null = await http.post<{ url: string }>('/projects/share-url', params)
    loader.hide()

    return result
  }

  async function getPublishMetadata(projectId: number): Promise<TPublishMetadata | null> {
    return http.get<TPublishMetadata>(`/projects/${projectId}/publish-metadata`)
  }

  return { validateGAID, saveDetails, getPublishMetadata }
})
