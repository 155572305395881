<script setup lang="ts">
  import { useRouter } from 'vue-router'

  const props = defineProps<{
    href?: string
    to?: string
    target?: string
    click?: () => void
    iconLeft?: string
    iconRight?: string
    disabled?: boolean
  }>()

  const router = useRouter()

  const onClick = function () {
    if (props.disabled) {
      return
    } else if (props.href) {
      return true
    } else if (props.to) {
      router.push(props.to)
    } else if (props.click) {
      props.click()
    }
  }
</script>

<template>
  <a
    class="inline-flex items-center gap-1.5 font-medium whitespace-nowrap leading-4 select-none"
    :href="props.href ?? undefined"
    :target="props.href ?? '_self'"
    :class="props.disabled ? 'cursor-not-allowed text-blue-90' : 'cursor-pointer text-blue-50 hover:underline'"
    @click="onClick"
  >
    <des-icon :icon="['fa-light', iconLeft]" class="h-icon w-icon" v-if="iconLeft"></des-icon>
    <slot></slot>
    <des-icon :icon="['fa-light', iconRight]" class="h-icon w-icon" v-if="iconRight"></des-icon>
  </a>
</template>
