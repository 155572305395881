export function getTimeDifference(dateString: string): string {
  const pastDate: Date = new Date(dateString)
  const currentDate: Date = new Date()
  const timeDifference: number = currentDate.getTime() - pastDate.getTime()

  const minutesDifference: number = Math.floor(timeDifference / 1000 / 60)

  if(minutesDifference < 1) {
    const secondsDifference: number = Math.floor(timeDifference / 1000)
    return `${secondsDifference} ${getSecondsWord(secondsDifference)} ago`
  }

  if(minutesDifference < 60) {
    return `${minutesDifference} ${getMinutesWord(minutesDifference)} ago`
  }

  const hoursDifference: number = Math.floor(minutesDifference / 60)
  if(hoursDifference < 24) {
    return `${hoursDifference} ${getHoursWord(hoursDifference)} ago`
  }

  const daysDifference: number = Math.floor(hoursDifference / 24)
  if(daysDifference < 31) {
    return `${daysDifference} ${getDaysWord(daysDifference)} ago`
  }

  const monthsDifference: number = Math.floor(daysDifference / 30.5)
  if(monthsDifference < 12) {
    return `${monthsDifference} ${getMonthsWord(monthsDifference)} ago`
  }

  const yearsDifference: number = Math.floor(monthsDifference / 12)
  return `${yearsDifference} ${getYearsWord(yearsDifference)} ago`
}

function getSecondsWord(seconds: number): string {
  return seconds === 1 ? "second" : "seconds"
}

function getMinutesWord(minutes: number): string {
  return minutes === 1 ? "minute" : "minutes"
}

function getHoursWord(hours: number): string {
  return hours === 1 ? "hour" : "hours"
}

function getDaysWord(days: number): string {
  return days === 1 ? "day" : "days"
}

function getMonthsWord(months: number): string {
  return months === 1 ? "month" : "months"
}

function getYearsWord(years: number): string {
  return years === 1 ? "year" : "years"
}
