<script setup lang="ts">
  import { useTourStore } from '@/stores/tour'
  import { useBrowserStore } from '@/stores/browser'
  import { track } from '@/helpers/mixpanelDes'
  import DesMenu from '@/components/menu/DesMenu.vue'
  import DesMenuItem from '@/components/menu/DesMenuItem.vue'
  import DesBarScreenCastomatic from '@/components/bar/DesBarScreenCastomatic.vue'

  const tour = useTourStore()
  const browser = useBrowserStore()

  function helpClicked() {
    track('help-nav-clicked')
    browser.openNewWindowTab('https://intercom.help/designrr/en/')
  }

  function tourClicked() {
    track('tour-nav-clicked')
    tour.show()
  }

  function facebookClick() {
    track('facebook-nav-clicked')
    browser.openNewWindowTab(' https://www.facebook.com/groups/439556516151256')
  }

  function youtubeClicked() {
    track('youtube-nav-clicked')
    browser.openNewWindowTab('https://www.youtube.com/channel/UCkvgzp1-EHepnguIDpnL0Sg')
  }

  function cannyClick() {
    track('canny-nav-clicked')
    browser.openNewWindowTab('https://designrr.canny.io/ebooks')
  }

  function castomaticClicked() {
    track('castomatic-nav-clicked')
    document.getElementById('som_record_button_cqeIeclY')?.click()
  }
</script>

<template>
  <DesBarScreenCastomatic />
  <DesMenu bottom>
    <template #toggle>
      <div
        v-des-track="'help-bar-clicked'"
        class="relative rounded hover:bg-white-gray cursor-pointer transition-colors inline-flex"
      >
        <des-icon icon="fa-light fa-circle-question" class="w-6 h-6 p-2 translate-y-1" />
      </div>
    </template>
    <DesMenuItem
      @click="helpClicked"
      icon="fa-light fa-graduation-cap"
      class="w-80 py-2"
      description="Looking to learn about Designrr? Check out our learning center."
      external
    >
      Learning center
    </DesMenuItem>
    <DesMenuItem
      @click="tourClicked"
      class="w-80 py-2 pr-8"
      icon="fa-light fa-flag"
      description="Looking to quickly get started? Take our tour!"
    >
      Take a tour
    </DesMenuItem>
    <DesMenuItem
      @click="youtubeClicked"
      icon="fa-brands fa-youtube"
      class="w-80 py-2"
      description="Includes excellent explanatory videos"
      external
    >
      Explore our Youtube Channel
    </DesMenuItem>
    <DesMenuItem
      @click="facebookClick"
      icon="fa-brands fa-square-facebook"
      class="w-80 py-2"
      description="Join our community."
      external
    >
      Ask in our Facebook Group
    </DesMenuItem>
    <DesMenuItem
      @click="cannyClick"
      icon="fa-light fa-comment-lines"
      class="w-80 py-2"
      description="Have any feedback? We would love to hear from you!"
      external
    >
      Give feedback
    </DesMenuItem>
    <DesMenuItem
      @click="castomaticClicked"
      icon="fa-light fa-video"
      class="w-80 py-2 pr-8 relative"
      description="Have a specific issue you would like us to see? Record your screen."
    >
      Record your screen
    </DesMenuItem>
  </DesMenu>
</template>
