<script setup lang="ts">
  import { useUserStore } from '@/stores/user'

  const { user: { avatar, gravatar } } = useUserStore()
</script>

<template>
  <div class="flex relative leading-none cursor-pointer">
    <img class="w-9 h-9 rounded-full object-contain" v-if="avatar" :src="avatar" alt="" />
    <img class="w-9 h-9 rounded-full object-contain" v-else :src="gravatar + '36'" alt="" />
  </div>
</template>
