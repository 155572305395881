import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { DesTooltip } from './directives/DesTooltip'
//import { DesHorizontalScroll } from './directives/DesHorizontalScroll'
import { DesTour } from './directives/DesTour'
import { DesTrack } from './directives/DesTrack'
import { FontAwesomeIcon, FontAwesomeLayers } from './font-awesome'
import App from './App.vue'
import router from './router'
import './index.css'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.component('des-icon', FontAwesomeIcon)
app.component('des-icon-layers', FontAwesomeLayers)
app.directive('des-tooltip', DesTooltip)
//app.directive('des-horizontal-scroll', DesHorizontalScroll)
app.directive('des-tour', DesTour)
app.directive('des-track', DesTrack)
app.mount('#app')
