import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/user'
import { http } from '@/helpers/http'
import { useStorage } from '@vueuse/core'
import { track } from '@/helpers/mixpanelDes'
import type { TUserSortOptions } from '@/types/projects'
import type { TFlipbook, TFlipbookProjectOptions, TFlipbookProjects, TProjectFlipbook } from '@/types/flipbooks'

export const useFlipbooksStore = defineStore('flipbooks', () => {
  const sortOptions = ref<TUserSortOptions>({})
  const user = useUserStore()
  const flipbooks = ref<TProjectFlipbook[]>([])

  async function createFlipbook(projectId: number): Promise<TFlipbook | null> {
    return http.post<TFlipbook>('/create_flipbook', { projectId })
  }

  async function createFlipbookProject(fileLink: string, fileName: string, fileUrl: string, description: string): Promise<{id: number, token: string, message?: string} | null> {
    return http.post<{id: number, token: string, message?: string}>('/create_flipbook_project', { fileLink, fileName, fileUrl, description })
  }

  async function clearTmpPdfFiles(): Promise<string> {
    return http.get('/clear_tmp_pdf_files', {}, true)
  }

  async function getFlipbookProjectOptions(projectId: number): Promise<TFlipbookProjectOptions | null> {
    return http.post<TFlipbookProjectOptions>('/get_flipbook_project_options', { projectId })
  }

  async function updateProjectData(projectId: number, options: string): Promise<string> {
    return http.post('/set_flipbook_project_options', { projectId, options }, true)
  }

  async function getFlipbookInfo(data: TFlipbook): Promise<string> {
    const isRTL = data.isRTL
    const flipbook = JSON.parse(data.flipbook)
    const { id, token } = flipbook

    return await buildFlipbookUrl(id, token, undefined, isRTL)
  }

  async function buildFlipbookUrl(id: number, token: string, type?: string, isRTL?: boolean): Promise<string> {
    const whiteLabel = await user.getExtraSettingByKey(user.userSettingLabels.FLIPBOOK_LABEL)
    const hideShare = (await user.getExtraSettingByKey(user.userSettingLabels.FLIPBOOK_HIDE_SOCIAL_SHARE)) === '1'
    const baseUrl = window.location.origin

    return constructFlipbookUrl(
      id,
      token,
      isRTL ? isRTL : false,
      hideShare,
      whiteLabel,
      baseUrl,
      window.location.href,
      type,
    )
  }

  function constructFlipbookUrl(
    id: number,
    token: string,
    isRTL: boolean,
    hideShare: boolean,
    whiteLabel: string,
    baseUrl: string,
    windowLocationHref: string,
    type?: string,
  ): string {
    const url = new URL(buildBaseUrl(baseUrl, whiteLabel, windowLocationHref))
    const params = new URLSearchParams({
      id: id.toString(),
      token: token,
    })

    if (type) {
      params.append('type', type)
    }

    if (isRTL) {
      params.append('rtl', isRTL.toString())
    }

    params.append('h', genShareToken(hideShare).toString())

    return url.href + '?' + params.toString()
  }

  function buildBaseUrl(baseUrl: string, whiteLabel: string, windowLocationHref: string): string {
    const flipbookDomain = 'designrr.page'

    if (whiteLabel && whiteLabel !== '') {
      return 'https://' + whiteLabel + '.' + flipbookDomain
    } else if (isOnApp(windowLocationHref)) {
      //the flipbook server only works for app
      return 'https://' + flipbookDomain
    } else {
      //for stage or local we don't use a different domain
      return baseUrl + '/flipbook'
    }
  }

  function genShareToken(hideShare: boolean): number {
    const rand = getRandomInt(1000, 5000) * 2 //even number
    return hideShare ? rand + 1 : rand
  }

  function getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  function isOnApp(windowLocationHref: string): boolean {
    return windowLocationHref.indexOf('https://app.designrr.io') >= 0
  }

  async function fetchFlipbooks(query?: string) {
    sortOptions.value = useStorage('sortOptions', {} as TUserSortOptions).value

    const data: TFlipbookProjects | null = await http.post<TFlipbookProjects | null>('/flipbook_projects', {
      query: query ? query : '',
    })
    if (!data) {
      throw new Error(`Failed to fetch flipbooks`)
    }

    flipbooks.value = JSON.parse(data.flipbooks)
    sort(sortOptions.value[user.user.id]?.projects)
  }

  function changeSharing(url: string, hideShare: boolean): string {
    const _url = url.split('&h=')[0] //TODO use an url parser, this will fail if later we add more params at the end
    const share = genShareToken(hideShare)
    return _url + '&h=' + share
  }

  async function renameFlipbook(id: number, name: string) {
    const res = await fetch('/flipbook_projects/rename_project', {
      method: 'POST',
      body: JSON.stringify({
        id,
        name,
      }),
    })
    if (!res.ok) {
      throw new Error(`Failed to rename flipbook`)
    }
    const data = await res.json()
    return data
  }

  async function deleteFlipbook(project: TProjectFlipbook) {
    const index = flipbooks.value.findIndex((item) => {
      return project.id === item.id
    })
    if (index >= 0) {
      flipbooks.value.splice(index, 1)
      const res = await fetch(`/flipbook_projects/delete`, {
        method: 'POST',
        body: JSON.stringify({
          id: project.id,
        }),
      })
      if (!res.ok) {
        throw new Error(`Failed to delete flipbook`)
      }
      track('deleted-flipbook-project', {
        name: project.name,
        pdf_url: project.pdf_url,
        id: project.id,
      })
    }
  }

  function sort(sortBy: string) {
    switch (sortBy) {
      case 'az':
        flipbooks.value.sort((a, b) => a.name.localeCompare(b.name))
        break
      case 'za':
        flipbooks.value.sort((a, b) => b.name.localeCompare(a.name))
        break
      case 'oldest':
        flipbooks.value.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
        break
      case 'newest':
        flipbooks.value.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
        break
    }
  }

  return {
    createFlipbook,
    createFlipbookProject,
    flipbooks,
    updateProjectData,
    getFlipbookProjectOptions,
    clearTmpPdfFiles,
    getFlipbookInfo,
    fetchFlipbooks,
    changeSharing,
    renameFlipbook,
    deleteFlipbook,
    buildFlipbookUrl,
    sort,
  }
})
