<script setup lang="ts">
  import { ref } from 'vue'
  import { onClickOutside } from '@vueuse/core'
  import { useSearchStore } from '@/stores/search'
  import DesSearchResults from '@/components/DesSearchResults.vue'
  import DesSearch from '@/components/DesSearch.vue'

  const search = useSearchStore()
  const target = ref<HTMLElement | null>(null)

  onClickOutside(target, () => {
    search.hideSearchResults()
  })

  defineProps<{
    class?: string
    placeholder?: string
  }>()

  function queryChanged(query: string) {
    if (searchComponent?.value?.input?.value) {
      searchComponent.value.input.value = query
    }
  }

  const searchComponent = ref<typeof DesSearch | null>(null)
</script>

<template>
  <div
    class="relative transition-all duration-100"
    ref="target"
    @click="search.showDefaultResultsIfNeeded()"
    :class="search.resultsHidden ? 'w-128' : 'w-192 shadow-lg'"
  >
    <DesSearch
      ref="searchComponent"
      :value="''"
      @click="search.showSearchResults()"
      @change="(res) => search.searchAll(res ? res : '')"
      placeholder="Search projects and docs"
    />
    <DesSearchResults @queryChanged="(query) => queryChanged(query)" v-if="!search.resultsHidden" />
  </div>
</template>
