export default class TimerUtils {
  static async wait(milliseconds: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve()
      }, milliseconds)
    })
  }

  static formatElapsedTime = function (milliseconds: number): string {
    const seconds = Math.round(milliseconds / 1000)
    let intervalString

    const SECONDS_IN_MINUTE = 60
    const MAX_SECONDS_TO_DISPLAY_AS_SECONDS = 120

    if (seconds < MAX_SECONDS_TO_DISPLAY_AS_SECONDS) {
      intervalString = `${seconds} sec`
    } else {
      const minutes = Math.round(seconds / SECONDS_IN_MINUTE)
      intervalString = `${minutes} min`
    }
    return intervalString
  }
  static computeAndFormatElapsedTime = function (startAt: Date) {
    const finishedAt = new Date()
    const milliseconds = finishedAt.getTime() - startAt.getTime()
    return TimerUtils.formatElapsedTime(milliseconds)
  }
}
