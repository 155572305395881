import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { http } from '@/helpers/http'
import { useStorage } from '@vueuse/core'
import type {
  TCombinedDocumentSearchResultsPaginated,
  TCombinedSearchResult,
  TCombinedSearchResults,
  TCombinedSearchResultsRequest,
} from '@/types/search'
import { useProjectsStore } from '@/stores/projects'
import { useAudiobooksStore } from '@/stores/audiobooks'
import { useFlipbooksStore } from '@/stores/flipbooks'
import { useDraftsStore } from '@/stores/drafts'
import { useLoaderStore } from '@/stores/loader'
import { useUserStore } from '@/stores/user'
import { useRouter } from 'vue-router'

export const useSearchStore = defineStore('search', () => {
  const combinedSearchResults = ref<TCombinedSearchResults>([])
  const lastSearchesLabel: string = 'last-searches'
  const resultsHidden = ref<boolean>(false)
  const recentSearchesHidden = ref<boolean>(false)
  const searchQuery = ref<string>('')
  const resultsFor = ref<string>('')
  const noSearchResults = ref<boolean>(false)
  const projects = useProjectsStore()
  const audiobooks = useAudiobooksStore()
  const flipbooks = useFlipbooksStore()
  const drafts = useDraftsStore()
  const loader = useLoaderStore()
  const router = useRouter()
  const user = useUserStore()
  const results = computed(() => combinedSearchResults.value)

  async function fetchAllResults(paramQuery?: string) {
    const queryToUse = paramQuery ? paramQuery : searchQuery.value
    resultsFor.value = queryToUse

    await Promise.all([
      projects.fetchProjects(0, false, 'newest', queryToUse),
      audiobooks.fetchAudiobooks(queryToUse),
      flipbooks.fetchFlipbooks(queryToUse),
      drafts.fetch('all', false, '', queryToUse),
    ])
  }

  function getLastSearchesUserLabel(): string {
    return lastSearchesLabel + '-' + user.user.id
  }

  function handleLastSearches(query: string): void {
    const lastSearches = useStorage<string[]>(getLastSearchesUserLabel(), [])

    if (!lastSearches.value.includes(query)) {
      lastSearches.value.push(query)
      if (lastSearches.value.length > 5) {
        lastSearches.value.shift()
      }
    } else {
      lastSearches.value = lastSearches.value.filter((item) => item !== query).concat(query)
    }
  }

  function sortResults(results: TCombinedSearchResults): TCombinedSearchResults {
    return results.sort(
      (a: TCombinedSearchResult, b: TCombinedSearchResult) =>
        new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
    )
  }

  async function searchAll(query: string, skipSavingHistory?: boolean): Promise<void> {
    searchQuery.value = query
    resultsFor.value = query
    noSearchResults.value = false

    if (query.trim() === '') {
      combinedSearchResults.value = []
      return
    }

    const limit: number = 6
    const projectPromise: Promise<TCombinedSearchResultsRequest> = http.post<TCombinedSearchResultsRequest>(
      '/search_projects_audiobooks_and_flipbooks',
      { query, limit },
    )

    const docPromise: Promise<TCombinedDocumentSearchResultsPaginated> =
      http.post<TCombinedDocumentSearchResultsPaginated>('/get_drafts/1/all/newest/6', {
        standarize: true,
        query,
      })

    const [projectResults, docResults] = await Promise.all([projectPromise, docPromise])
    const toReturn: TCombinedSearchResults =
      projectResults?.data && docResults?.results ? projectResults.data.concat(docResults.results) : []

    combinedSearchResults.value = toReturn.length ? sortResults(toReturn).slice(0, limit) : []
    const found: boolean = combinedSearchResults.value.length > 0

    if (!skipSavingHistory) {
      if (found) {
        handleLastSearches(query)
      }
      recentSearchesHidden.value = true
    } else {
      recentSearchesHidden.value = false
    }

    if (!found) {
      noSearchResults.value = true
    }

    showSearchResults()
  }

  function clearAutocompleteSearchResults() {
    combinedSearchResults.value = []
    searchQuery.value = ''
  }

  function hideSearchResults() {
    resultsHidden.value = true
  }

  function showSearchResults() {
    resultsHidden.value = false
  }

  async function showAllResults() {
    loader.show('Searching...')
    const route: string = `/search/${searchQuery.value}`

    await fetchAllResults()
    clearAutocompleteSearchResults()

    loader.hide()
    router.push({ path: route })
  }

  function showDefaultResultsIfNeeded(): void {
    if (searchQuery.value !== '') {
      return
    }

    const lastSearches = useStorage<string[]>(getLastSearchesUserLabel(), [])
    if (!lastSearches.value.length) {
      return
    }

    searchAll(lastSearches.value[lastSearches.value.length - 1], true)
  }

  return {
    results,
    searchAll,
    fetchAllResults,
    clearAutocompleteSearchResults,
    hideSearchResults,
    showAllResults,
    showSearchResults,
    getLastSearchesUserLabel,
    showDefaultResultsIfNeeded,
    noSearchResults,
    recentSearchesHidden,
    resultsFor,
    resultsHidden,
    searchQuery,
  }
})
