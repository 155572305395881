<script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import { useUserStore } from '@/stores/user'

  const show = ref<boolean>(false)
  const users = useUserStore()

  onMounted(() => {
    show.value = users.user.account_status === 'REBILL_FAIL_DEACTIVATION'
  })

  function close() {
    show.value = false
  }
</script>

<template>
  <Transition leave-to-class="-top-20">
    <div
      v-if="show"
      class="bg-red-50 text-white text-base transition-all absolute top-0 left-0 right-0 h-16 flex items-center justify-center gap-4 z-50"
    >
      <div class="flex bg-white rounded-full p-0.5">
        <des-icon icon="fa-light fa-exclamation" class="w-icon h-icon text-red-50" />
      </div>

      <div class="alert-banner-message">
        IMPORTANT: There is an issue with your payment.
        <a
          class="font-bold underline"
          target="_blank"
          href="https://learn.designrr.io/en/articles/7863887-update-payment-information"
        >
          Please click to learn more
        </a>
      </div>
      <div class="flex cursor-pointer hover:bg-red-40 p-1 rounded transition-colors" @click="close">
        <des-icon icon="fa-light fa-times" class="w-icon h-icon" />
      </div>
    </div>
  </Transition>
</template>
