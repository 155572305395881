import type { TUser } from '@/types/user'
import TimerUtils from '@/helpers/timerUtils'

declare global {
  interface Window {
    SOM?: {
      Recorder: Function
      addRecordButtonAfterId: Function
    }
  }
}

export default class Screencastomatic {
  static async setupScreencastomaticScript(user: TUser): Promise<void> {
    if (!user) {
      console.log('Skip loading screencastomatic, user is not logged in')
      return
    }
    const targetElementId = 'screencastomatic-target'
    if (!document.getElementById(targetElementId)) {
      console.log('SKIP screencastomatic no target element on this page')
      return
    }

    const scriptId = 'som_recorder_cqeIeclY'
    if (document.getElementById(scriptId)) {
      if (document.querySelector('img#som_record_button_cqeIeclY')) {
        console.log('Attempting to setup screencastomatic...surprised by finding the record button already present')
        return
      }
      Screencastomatic.setupScreencastomaticButtonAndPlayer(user, targetElementId)
      return
    }
    const oHead = document.getElementsByTagName('BODY')[0]
    const oScript = document.createElement('script')
    oScript.id = scriptId
    oScript.type = 'text/javascript'
    oScript.src = '//screencast-o-matic.com/api/som.js'

    return new Promise<void>((resolve, reject) => {
      oScript.onerror = () => {
        reject(new Error('Failed to load screencastomatic script'))
      }

      oScript.onload = async () => {
        await TimerUtils.wait(500)
        Screencastomatic.setupScreencastomaticButtonAndPlayer(user, targetElementId)
        resolve()
      }
      oHead.appendChild(oScript)
    })
  }

  static setupScreencastomaticButtonAndPlayer(user: TUser, targetElementId: string): void {
    if (!window?.SOM?.Recorder) {
      console.log('ERROR Screencastomatic objects were not created')
      return
    }

    window.SOM.Recorder({
      id: 'cqeIeclY',
      customData: { email: user.email },
      buttonImageUrl: '/assets/images/record-button.svg',
    }).addRecordButtonAfterId(targetElementId)
  }
}
