<script setup lang="ts">
  import { useTooltipStore } from '@/stores/tooltip'
  const tip = useTooltipStore()
</script>

<template>
  <Transition enter-from-class="opacity-0" leave-to-class="opacity-0" enter-active-class="delay-700">
    <div
      v-if="tip.visible"
      class="p-3 absolute transition-all z-50 max-w-sm text-xs duration-150 cursor-default select-none"
      :style="{ top: tip.position.y + 'px', left: tip.position.x + 'px' }"
      :class="{
        '-translate-x-1/2': tip.direction === 'top' || tip.direction === 'bottom',
        '-translate-y-1/2': tip.direction === 'left' || tip.direction === 'right',
        '-translate-x-full': tip.direction === 'left',
        '-translate-y-full': tip.direction === 'top',
      }"
      @mouseenter="tip.preventHide"
      @mouseleave="tip.hide"
    >
      <svg
        viewBox="0 0 12 12"
        class="w-3 fill-black-20 absolute"
        :class="{
          'rotate-180 top-0': tip.direction === 'bottom',
          'rotate-90 left-0': tip.direction === 'right',
          '-rotate-90 right-0': tip.direction === 'left',
          'rotate-0 bottom-0': tip.direction === 'top',
          'left-1/2 -translate-x-1/2': tip.direction === 'top' || tip.direction === 'bottom',
          'top-1/2 -translate-y-1/2': tip.direction === 'right' || tip.direction === 'left',
        }"
      >
        <path d="M4.33 4.5a2 2 0 0 0 2.88 0L11.55 0H0l4.33 4.5Z" />
      </svg>
      <div id="des-tooltip" class="px-4 py-2 text-white bg-black-20 rounded" v-html="tip.message"></div>
    </div>
  </Transition>
</template>

<style>
  #des-tooltip a {
    color: theme("colors.blue.60");
  }

  #des-tooltip a:hover {
    text-decoration: underline;
  }
</style>