<script setup lang="ts">
  import { ref } from 'vue'
  import { useDebounceFn } from '@vueuse/core'

  const props = defineProps<{
    name: string;
    big?: boolean;
  }>()

  const emits = defineEmits<{
    edit: [newValue: string]
  }>()

  const input = ref<HTMLInputElement | null>(null)
  const editMode = ref<boolean>(false)
  const onInput = useDebounceFn(() => input.value?.value && emits('edit', input.value.value), 1000)
  const onOut = () => {
    editMode.value = false

    if (props.name !== input.value?.value) {
      input.value?.value && emits('edit', input.value.value)
    }
  }

  const mouseOut = (event: MouseEvent) => {
    if (event.target !== document.activeElement) {
      editMode.value = false
    }
  }
</script>

<template>
  <div class="group relative inline-block w-full" v-des-tooltip="name" :key="editMode.toString()">
    <input
      class="max-w-full w-full py-1 pr-10 hover:pl-3 focus:pl-3 bg-transparent focus:bg-white hover:bg-white font-medium text-black-10 border transition-all border-transparent hover:border-black-40 focus:border-blue-50 rounded-lg outline-none overflow-ellipsis overflow-hidden"
      ref="input"
      :value="name"
      :class="big ? 'text-base' : 'text-sm'"
      :readonly="!editMode"
      @input="onInput()"
      @mouseenter="editMode = true"
      @mouseleave="mouseOut"
      @focus="editMode = true"
      @blur="onOut"
      @click.stop.prevent="input?.focus(); input?.select();"
    />
    <des-icon
      icon="fa-light fa-pen"
      class="w-icon h-icon absolute right-3 top-1.5 pointer-events-none transition-opacity opacity-0 group-hover:opacity-100 group-focus-within:opacity-100"
    ></des-icon>
  </div>
</template>
