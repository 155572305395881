<script setup lang="ts">
  import { ref, watch, onMounted, defineProps, onBeforeUnmount } from 'vue'
  import { useDropZone, useFileDialog } from '@vueuse/core'
  import { useLocalImportStore } from '@/stores/local-import'
  import { useRemoteImportStore } from '@/stores/remote-import'
  import { useCreatorStore } from '@/stores/creator'
  import { useSnackbarStore } from '@/stores/snackbar'
  import { useRouter } from 'vue-router'
  import { ERemoteImportURLType } from '@/types/remote-imports'
  import { useModalStore } from '@/stores/modal'

  const dropZoneRef = ref<HTMLDivElement>()
  const localImport = useLocalImportStore()
  const remoteImport = useRemoteImportStore()
  const creatorStore = useCreatorStore()
  const snack = useSnackbarStore()
  const typeIcon = ref<string | null>(null)
  const progress = ref<number>(0)
  const fileToUpload = ref<File | null>(null)

  const isOverGlobalDropZone = ref(false)
  const modal = useModalStore()
  const router = useRouter()
  const input = ref<HTMLInputElement | null>(null)

  const { isOverDropZone: globalIsOverDropZone } = useDropZone(document.documentElement, (files) => {
    isOverGlobalDropZone.value = false
  })

  watch(globalIsOverDropZone, (newVal) => {
    isOverGlobalDropZone.value = newVal
  })

  const props = defineProps<{
    files: FileList | null
    url: string | null
  }>()

  watch(
    () => props.files,
    (newFiles) => {
      if (newFiles && newFiles.length === 1) {
        checkUpload(newFiles[0])
      }
    },
  )

  watch(
    () => props.url,
    (newUrl) => {
      if (newUrl && newUrl !== '') {
        checkUrl(newUrl)
      }
    },
  )

  const acceptedFileTypes = [
    {
      type: 'docx',
      accept: ['.doc', '.docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    },
    {
      type: 'audio',
      accept: [
        'audio/mp4',
        '.wav',
        'audio/aac',
        'audio/x-aac',
        'audio/wav',
        'audio/mpeg',
        'audio/mp3',
        'audio/x-hx-aac-adts',
        'audio/vnd.dlna.adts',
        'audio/x-m4a',
        '.m4a',
      ],
    },
    { type: 'pdf', accept: ['.pdf', 'application/pdf'] },
    { type: 'video', accept: ['video/mp4', 'video/x-ms-asf', 'video/quicktime'] },
  ]

  const icons = ['docx', 'audio', 'pdf', 'video']
  creatorStore.reset()

  const { isOverDropZone } = useDropZone(dropZoneRef, (files, event) => {
    const dataTransfer = event.dataTransfer
    if (dataTransfer) {
      const url = dataTransfer.getData('text/uri-list') || dataTransfer.getData('text/plain')
      if (url) {
        checkUrl(url)
      }
    }
    if (files && files.length === 1) {
      checkUpload(files[0])
    } else if (files && files.length > 1) {
      snack.add('You can drop only one file')
    }
  })

  const { open, files } = useFileDialog({
    accept: acceptedFileTypes.flatMap((type) => type.accept).join(','),
    multiple: false,
  })

  watch(files, (newFiles) => {
    if (newFiles && newFiles.length === 1) {
      checkUpload(newFiles[0])
    } else if (newFiles && newFiles.length > 1) {
      snack.add('You can drop only one file')
    }
  })

  onMounted(() => {
    localImport.dndFile = null
    remoteImport.dndUrl = null
  })

  const handleClick = () => {
    open()
  }

  const checkUrl = (url: string, silent = false) => {
    const urlPattern = /^(https?:\/\/)?(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(\/[a-zA-Z0-9-._~:?#@!$&'()*+,;=]*)*$/i
    if (urlPattern.test(url)) {
      remoteImport.dndUrl = url
      const urlType = localImport.detectUrlType(url)
      switch (urlType) {
        case ERemoteImportURLType.Audio:
          router.push('/creator/ebook/from-podcast')
          break
        case ERemoteImportURLType.Video:
          router.push('/creator/ebook/from-video')
          break
        case ERemoteImportURLType.YouTube:
          router.push('/creator/ebook/youtube')
          break
        case ERemoteImportURLType.Web:
          router.push('/creator/ebook/from-blog')
          break
        case ERemoteImportURLType.DocX:
          router.push('/creator/ebook/from-docx')
          break
        case ERemoteImportURLType.GDoc:
          router.push('/creator/ebook/from-gdoc')
          break
        case ERemoteImportURLType.Pdf:
          router.push('/creator/ebook/from-pdf')
          break
        default:
          snack.add('Incorrect URL')
      }
      return
    } else {
      if (!silent) {
        snack.add('Incorrect URL')
      }
    }
  }

  const checkUpload = (file: File) => {
    const fileType = file.type
    const fileName = file.name

    const foundType = acceptedFileTypes.find(({ accept }) =>
      accept.some((acceptedType) => fileType === acceptedType || fileName.endsWith(acceptedType)),
    )

    if (foundType) {
      typeIcon.value = icons[acceptedFileTypes.indexOf(foundType)]
      fileToUpload.value = file
      const interval = setInterval(() => {
        if (progress.value < 100) {
          progress.value += 1
        } else {
          clearInterval(interval)
          localImport.detectUpload(Array.from([file]))
        }
      }, 10)
    } else {
      snack.add('Unsupported file type')
    }
  }

  const pasteURL = async () => {
    const response = await modal.open<string>(() => import('@/components/modal/DesModalPasteURL.vue'))
    if (response) {
      checkUrl(response)
    }
  }
</script>

<template>
  <div
    v-if="!typeIcon"
    ref="dropZoneRef"
    class="px-4 py-2 border border-black-70 rounded-lg border-dashed justify-start items-center gap-3 inline-flex select-none cursor-pointer"
    :class="{
      'border-2 bg-white-gray border-blue-50': isOverDropZone === true || isOverGlobalDropZone === true,
    }"
  >
    <div class="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex flex-1 overflow-hidden">
      <div class="self-stretch justify-center items-center gap-2 inline-flex text-xs">
        <des-icon icon="fa-light fa-cloud-arrow-up" />
        <div class="self-stretch text-blue-10 text-sm font-normal leading-none text-center">
          Drag and drop or
          <span class="text-blue-50" @click="handleClick">browse</span>
          to import or
          <span class="text-blue-50" @click="pasteURL">paste URL</span>
        </div>
        {{ typeIcon }}
      </div>

      <div
        class="self-stretch text-black-30 text-xs font-normal leading-none truncate text-center"
        @click="handleClick"
      >
        .pdf, .docx, .mp3, .mp4
      </div>
    </div>
  </div>
  <div
    v-else
    class="px-4 py-2 bg-white-gray border border-white-gray rounded-lg justify-start items-center gap-3 inline-flex hover:border-black-90 select-none cursor-pointer w-full"
  >
    <div class="bg-white w-11 h-11 flex justify-center items-center rounded-md flex-shrink-0">
      <img :src="`/dashboard/new-project/ico-from-${typeIcon}.png`" alt="" class="h-6" />
    </div>
    <div class="flex flex-col gap-2 max-w-full flex-1 overflow-hidden">
      <div class="text-blue-10 text-sm font-normal truncate overflow-hidden text-ellipsis whitespace-nowrap">
        {{ fileToUpload?.name }}
      </div>

      <div class="flex items-center gap-3">
        <div class="bg-black-90 rounded-md flex-1 h-1">
          <div class="bg-blue-50 h-1 rounded-md" :style="`width: ${progress}%`"></div>
        </div>
        <div class="self-stretch text-black-40 text-xs font-normal leading-none truncate">{{ progress }}%</div>
      </div>
    </div>
  </div>
</template>
