import { ref, type DirectiveBinding } from 'vue'
import { defineStore } from 'pinia'
import { useUserStore } from '@/stores/user'
import { type TTour } from '@/types/tour'

const tours: TTour[] = [
  {
    title: 'Create new projects 🚀',
    content: `Transform any content into a masterpiece using a collection of stunning templates. Leverage the power of AI to create high-quality ebooks effortlessly.`,
    name: 'bar-create',
    video: 'https://designrr.s3.amazonaws.com/videos/create.mp4',
    order: 1,
  },
  {
    title: 'Your projects 📚 🎧',
    content: `Manage your creations effortlessly - e-books, audiobooks, transcriptions - all in one place. Once you create your first project, it's automatically saved here for future edits.`,
    name: 'menu-projects',
    video: 'https://designrr.s3.amazonaws.com/videos/projects.mp4',
    order: 2,
  },
  {
    title: 'Media manager 📸',
    content: `Dive into a world of visual creativity with our Media Manager. Explore a wide range of images provided by Unsplash, upload your own, organize folders, mark your favorites, and let your creativity unfold.`,
    name: 'menu-media',
    video: 'https://designrr.s3.amazonaws.com/videos/media.mp4',
    order: 3,
  },
  {
    title: 'Your draft lives here ✍🏻✨',
    content: `In the "Docs" space words take center stage. Create, edit, and save your drafts effortlessly, while using the latest AI power to take your writing to the next level! All content you import or create using Wordgenie lives here.  Take advantage by finalizing your draft using fast and simple editing tools before moving your draft to the design stage.`,
    name: 'menu-docs',
    video: 'https://designrr.s3.amazonaws.com/videos/documents.mp4',
    order: 4,
  },
]

export const useTourStore = defineStore('tour', () => {
  const user = useUserStore()
  const visible = ref(false)
  const gap = ref({ x: 0, y: 0, w: 0, h: 0 })
  const backdrop = ref<{ width: number; height: number }>({ width: 0, height: 0 })
  const direction = ref<'top-left' | 'top-center' | 'top-right' | 'left' | 'right'>('top-center')

  const list = ref<{ element: HTMLElement; id: string }[]>([])
  const index = ref(0)
  const item = ref<TTour | null>(null)

  function show() {
    const currentItem = list.value[index.value]
    const currentElement = currentItem.element
    const sourceItem = tours.find((t) => t.name === currentItem.id)

    if (!currentElement || !sourceItem) return

    item.value = sourceItem

    document.body.classList.add('overflow-hidden')
    document.documentElement.scrollTop = 0

    const rect = currentElement.getBoundingClientRect()
    gap.value = {
      x: rect.left,
      y: rect.top,
      w: rect.width,
      h: rect.height,
    }

    const { width, height } = document.documentElement.getBoundingClientRect()
    backdrop.value = { width, height }

    direction.value = (() => {
      if (rect.top < 100) {
        if (rect.left < 200) return 'top-left'
        else if (window.innerWidth - rect.left - rect.width < 200) return 'top-right'
        else return 'top-center'
      } else if (rect.left < window.innerWidth / 2) return 'left'
      else return 'right'
    })()
    visible.value = true
  }

  function hide() {
    visible.value = false
    index.value = 0
    document.body.classList.remove('overflow-hidden')
    user.saveExtraSetting(user.userSettingLabels.ONBOARDING_COMPLETED, '1')
  }

  function add(el: HTMLElement, binding: DirectiveBinding) {
    const sort = (a: { element: HTMLElement; id: string }, b: { element: HTMLElement; id: string }) =>
      tours.findIndex((item) => item.name === a.id) - tours.findIndex((item) => item.name === b.id)

    list.value.push({ element: el, id: binding.value })
    list.value.sort(sort)
  }

  function remove(el: HTMLElement) {
    list.value = list.value.filter((item) => item.element !== el)
  }

  function next() {
    index.value += 1
    show()
  }

  function prev() {
    index.value -= 1
    show()
  }

  // mock:
  // setTimeout(() => show(), 1000)
  // user.saveExtraSetting(user.userSettingLabels.ONBOARDING_COMPLETED, '0')

  window.addEventListener('resize', () => {
    if (!visible.value) return

    const { width, height } = document.documentElement.getBoundingClientRect()
    backdrop.value = { width, height }
    show()
  })

  return { visible, direction, gap, item, list, current: index, backdrop, show, hide, add, remove, next, prev }
})
