export const columnsCount = (element?: HTMLElement) => {
  if (!element) return 0

  const containerComputerStyle = window.getComputedStyle(element)
  const colsNumber = containerComputerStyle
    .getPropertyValue('grid-template-columns')
    .replace(' 0px', '')
    .split(' ')
    .length

  return colsNumber
}
