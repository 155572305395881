<script setup lang="ts">
  import { ref, computed } from 'vue'
  import { useRouter } from 'vue-router'
  import { useUpgradeStore } from '@/stores/upgrade'
  import DesButton from '@/components/button/DesButton.vue'
  import DesHeading from '@/components/DesHeading.vue'
  import DesButtonLink from '@/components/button/DesButtonLink.vue'

  const router = useRouter()
  const upgrade = useUpgradeStore()

  const props = defineProps<{
    icon?: string
    itemName?: string
    itemsName?: string
    newRoute?: string
    buttonDisabled?: boolean
    bottomLabelDisabled?: boolean
    customMainLabelText?: string
    customBottomLabelText?: string
    noInvoicesYet?: boolean
    goToProjectsButton?: boolean
    click?: () => void
  }>()

  const itemName = ref<string>(props.itemName ?? '')
  const itemsName = ref<string>(props.itemsName ?? '')
  itemsName.value = itemName.value !== '' && itemsName.value === '' ? itemName.value + 's' : itemsName.value
  const newRoute = ref<string>(props.newRoute ?? '')
  const iconClass = 'fa-light fa-'
  const icon = ref<string>(props.icon ? iconClass + props.icon : iconClass + 'magnifying-glass')

  function handleClick() {
    onClick()

    if (!newRoute.value || newRoute.value === '') {
      return
    }

    router.push(newRoute.value)
  }

  function goToProjects() {
    router.push('/projects')
  }

  function onClick() {
    if (props.click) {
      props.click()
    }
  }

  const mainLabelText = computed(() => {
    if (props.customMainLabelText?.length) {
      return props.customMainLabelText
    }

    return `Looks like you haven't created any ${itemName.value} yet`
  })

  const bottomLabelText = computed(() => {
    if (props.customBottomLabelText?.length) {
      return props.customBottomLabelText
    }

    return `Fortunately, it's easy to create a new one`
  })
</script>

<template>
  <div class="flex flex-col justify-center items-center mt-6">
    <des-icon :icon="icon" class="h-24 w-24" />
    <DesHeading level="4" class="mb-2 mt-6">{{ mainLabelText }}</DesHeading>
    <p v-if="!props?.bottomLabelDisabled && !props?.noInvoicesYet" class="text-base mb-6">{{ bottomLabelText }}</p>
    <p v-if="props?.noInvoicesYet" class="text-base mb-6">
      If you
      <DesButtonLink @click="upgrade.showUpgradeModal">upgrade to paid account</DesButtonLink>
      or purchase credits, you'll see a history of your payments here.
    </p>
    <DesButton v-if="!props?.buttonDisabled && !props?.goToProjectsButton" @click="handleClick()">
      <des-icon icon="fa-light fa-plus" />
      Create your first {{ itemName }}
    </DesButton>
    <DesButton v-if="props?.goToProjectsButton && !props.goToProjectsButton">
      <des-icon icon="fa-light fa-plus" />
      Create your first {{ itemName }}
    </DesButton>
    <DesButton v-if="props?.goToProjectsButton" @click="goToProjects()">
      <des-icon icon="fa-light fa-folder" />
      Go to projects
    </DesButton>
  </div>
</template>
