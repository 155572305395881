import { defineStore } from 'pinia'
import type { TAllProductsCart, TPlanConfig } from '@/types/upgrade'
import { useUserStore } from '@/stores/user'
import { Products, getLabel, getIcon } from '@/helpers/product'
import { ThriveCartProduct } from '@/helpers/thriveCartProduct'
import type { TUser } from '@/types/user'
import { useModalStore } from '@/stores/modal'

export const useUpgradeStore = defineStore('upgrade', () => {

  const user = useUserStore()
  const idStandard: number[] = user.getIdStandard()
  const idPro: number[] = user.getIdPro()
  const idPremium: number[] = user.getIdPremium()
  const idAgencyPremium: number[] = user.getIdAgencyPremium()
  const accountTypeId: number | undefined = user?.user?.account_type
  const allProductsCartUrl: TAllProductsCart = getAllProductsCartUrl(user.user)
  const modal = useModalStore()

  const config: TPlanConfig[] = [
    {
      name: getLabel(Products.Standard),
      price: 27,
      icon: getIcon(Products.Standard),
      accountTypeIds: idStandard,
      currentPlan: idStandard.includes(accountTypeId),
      purchaseUrl: '',
      features: [
        'Standard Templates',
        'Unlimited PDF eBooks',
        'Page Numbering & Table Of Contents Generator',
      ],
    },
    {
      name: getLabel(Products.Pro),
      price: 97,
      icon: getIcon(Products.Pro),
      accountTypeIds: idPro,
      currentPlan: idPro.includes(accountTypeId),
      purchaseUrl: allProductsCartUrl.proUrl,
      features: [
        'Dynamic Templates',
        'Publish to Kindle',
        '3D Cover Creator',
      ],
    },
    {
      name: getLabel(Products.Premium),
      price: 297,
      icon: getIcon(Products.Premium),
      accountTypeIds: idPremium,
      currentPlan: idPremium.includes(accountTypeId),
      purchaseUrl: allProductsCartUrl.premiumUrl,
      features: [
        'Transcribe videos and audio',
        'Create Audiobooks',
      ],
    },
    {
      name: getLabel(Products.AgencyPremium),
      price: 497,
      icon: getIcon(Products.AgencyPremium),
      accountTypeIds: idAgencyPremium,
      currentPlan: idAgencyPremium.includes(accountTypeId),
      purchaseUrl: allProductsCartUrl.agencypremiumUrl,
      features: [
        'Custom Template Creator',
        'Collaborative eBooks with Client Interface',
        'Accounts for agency members',
      ],
    }
  ]

  function getAllProductsCartUrl (user: TUser): TAllProductsCart {
    return {
      proUrl: ThriveCartProduct.proProduct.getProductCartUrl(user),
      premiumUrl: ThriveCartProduct.premiumProduct.getProductCartUrl(user),
      agencypremiumUrl: ThriveCartProduct.agencyPremiumProduct.getProductCartUrl(user),
    }
  }

  function showUpgradeModal () {
    modal.open(() => import('@/components/modal/DesModalUpgrade.vue'))
  }

  return { config, getAllProductsCartUrl, showUpgradeModal }
})
