import { isProduction } from '@/helpers/envCheck'
import type { TUser } from '@/types/user'

export class ThriveCartProduct {
  constructor(
    public id: string,
    public product_tc_id: string,
    public label: string,
    public quantity: number,
    public priceCents: number | null,
  ) {}

  getPurchasePage(email: string, firstName: string, lastName: string): string {
    const passthroughVars = `?passthrough[customer_email]=${email}&passthrough[customer_firstname]=${firstName}&passthrough[customer_lastname]=${lastName}`
    return `https://checkout.designrr.io/${this.product_tc_id}/${passthroughVars}`
  }

  getProductCartUrl(user: TUser): string {
    const email = user.email
    const firstName = user.first_name ? user.first_name : ''
    const lastName = user.last_name ? user.last_name : ''
    return this.getPurchasePage(email, firstName, lastName)
  }

  static fromObject(obj: {
    id: string
    product_tc_id: string
    label: string
    quantity: number
    priceCents: number | null
  }): ThriveCartProduct {
    return new ThriveCartProduct(obj.id, obj.product_tc_id, obj.label, obj.quantity, obj.priceCents)
  }

  static get ai_credits_products(): ThriveCartProduct[] {
    return [
      {
        id: 'ai_credits_100000',
        product_tc_id: isProduction() ? 'additional-credits-wordgenie' : 'additional-credits-wordgenie-copy-2',
        label: 'Add Additional Credits - Wordgenie',
        quantity: 100000,
        priceCents: 1700,
      },
    ].map((obj) => ThriveCartProduct.fromObject(obj))
  }

  static get tts_minutes_products(): ThriveCartProduct[] {
    return [
      {
        id: 'credits_100000',
        product_tc_id: '100000-credits-audiobook',
        label: 'Add 100000 Credits - Audiobook',
        quantity: 100000,
        priceCents: 4700,
      },
    ].map((obj) => ThriveCartProduct.fromObject(obj))
  }

  static get audio_minutes_products(): ThriveCartProduct[] {
    return [
      {
        id: 'hours2',
        product_tc_id: '2-extra-hours-transcription',
        label: 'Add 2 Hours More Transcription',
        quantity: 120,
        priceCents: null,
      },
      {
        id: 'hours6',
        product_tc_id: '6-extra-hours-transcription',
        label: 'Add 6 Hours More Transcription',
        quantity: 360,
        priceCents: null,
      },
      {
        id: 'hours15',
        product_tc_id: '16-extra-hours-transcription',
        label: 'Add 15 Hours More Transcription',
        quantity: 960,
        priceCents: null,
      },
    ].map((obj) => ThriveCartProduct.fromObject(obj))
  }

  static get proProduct(): ThriveCartProduct {
    return new ThriveCartProduct('', 'designrr-pro-inapp-upgrade', 'Pro Upgrade', 1, null)
  }

  static get premiumProduct(): ThriveCartProduct {
    return new ThriveCartProduct('', 'designrr-premium-inapp-upgrade', 'Premium Upgrade', 1, null)
  }

  static get agencyPremiumProduct(): ThriveCartProduct {
    return new ThriveCartProduct('', 'designrr-agency-premium-inapp', 'Agency Premium Upgrade', 1, null)
  }
}
