<script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import { useUserStore } from '@/stores/user'
  import { useFeedbackStore } from '@/stores/feedback'
  import { useLoaderStore } from '@/stores/loader'
  import DesButton from '@/components/button/DesButton.vue'
  import DesHeading from '@/components/DesHeading.vue'
  import DesTextarea from '@/components/form/DesTextarea.vue'
  import DesModalFooter from '@/components/modal/DesModalFooter.vue'
  import DesModalContainer from '@/components/modal/DesModalContainer.vue'
  import DesModalHeader from '@/components/modal/DesModalHeader.vue'
  import DesModalClose from '@/components/modal/DesModalClose.vue'

  const user = useUserStore()
  const feedback = useFeedbackStore()
  const loader = useLoaderStore()

  const like = ref<1 | -1 | null>(null)
  const description = ref<string>('')
  const showThanks = ref<boolean>(false)

  async function submitFeedback() {
    loader.show('Sending...')
    const result: boolean = await feedback.submitFeedback(like.value, description.value)

    if (result) {
      showThanks.value = true
    }

    loader.hide()
  }

  async function cancel() {
    loader.show('Cancel...')
    await feedback.resetEntryCount()
    loader.hide()
  }

  onMounted(async () => await feedback.countEntries())
</script>

<template>
  <Transition enter-from-class="-bottom-96" leave-to-class="-bottom-96">
    <DesModalContainer
      class="!fixed bottom-6 right-6 transition-all duration-300 shadow-2xl"
      v-if="feedback.componentShown"
    >
      <DesModalHeader>
        <DesHeading level="4">Hi {{ user?.user?.first_name ?? '' }}, how do you like our new design?</DesHeading>
        <DesModalClose @click="cancel()" class="absolute" />
      </DesModalHeader>

      <div class="flex gap-3 mb-6">
        <div
          @click="like = like === 1 ? null : 1"
          :class="{ 'border-green-40 bg-green-97 text-green-40': like === 1 }"
          class="flex gap-1.5 items-center border border-black-90 rounded px-4 py-3 hover:border-green-40 hover:text-green-40 cursor-pointer transition-colors"
        >
          <des-icon class="w-icon h-icon" icon="fa-light fa-thumbs-up" />
          I love it!
        </div>
        <div
          @click="like = like !== -1 ? -1 : null"
          :class="{ 'border-red-40 bg-red-97 text-red-40': like === -1 }"
          class="flex gap-1.5 items-center border border-black-90 rounded px-4 py-3 hover:border-red-40 hover:text-red-40 cursor-pointer transition-colors"
        >
          <des-icon class="w-icon h-icon" icon="fa-light fa-thumbs-down" />
          Not so so
        </div>
      </div>

      <DesTextarea
        v-model="description"
        ref="embedInput"
        height="7"
        label="Can you elaborate? (optional)"
        placeholder="Write here your thoughts..."
      />

      <DesModalFooter>
        <DesButton @click="submitFeedback()" class="mr-4">Submit feedback</DesButton>
        <DesButton @click="cancel()" type="gray">Cancel</DesButton>
      </DesModalFooter>

      <div
        class="absolute inset-0 flex flex-col p-12 justify-center items-center text-center bg-white"
        v-if="showThanks"
      >
        <DesModalClose @click="feedback.componentShown = false" class="absolute" />
        <des-icon icon="fa-light fa-check-circle" class="text-green-40 w-16 h-16 mb-4" />
        <DesHeading level="3">Thank you!</DesHeading>

        <div class="mt-2">
          We appreciate your feedback.
        </div>
      </div>
    </DesModalContainer>
  </Transition>
</template>
