import { useTourStore } from '@/stores/tour'
import { type Directive, type DirectiveBinding } from 'vue'

export const DesTour: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const tour = useTourStore()
    tour.add(el, binding)
  },

  unmounted(el: HTMLElement) {
    const tour = useTourStore()
    tour.remove(el)
  },
}
