import type { TImportHelpers } from '@/types/remote-imports'

export class ImportTypes {
  static urlValidate = function (url: string) {
    if (!url || url.length === 0) {
      return false
    }
    const valid =
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        url
      )
    if (!valid) {
      return false
    }
    return true
  }

  static helpers: TImportHelpers = {
    docx: {
      module: 'docx',
      image: 'assets/import-sources/from-docx.svg',
      headingText: 'Import from Word DOCX',
      bookNamePlaceholder: 'Title',
      urlPlaceholder: 'Enter the URL of DOCX file',
      endPoint: '/import_docx',
      trackErrorMsg: 'error-importing-docx',
      trackSuccessTag: 'imported-docx',
      creationErrorMsg: 'Error extracting DOCX content.',
      downloadType: 'docx',
      processingLoadingMessage:
        'Converting DOCX: This may take a few minutes depending on the number of pages and images.',
      draftName: 'Imported from DOCX',
      buttonText: 'Now I know how to upload my docx! Next',
      intro:
        'Before uploading your Word Docx, remove headers & footers, footnotes and table of contents (Designrr can recreate them after import). Then check you have your headings defined. Click <a target="_blank" href="https://intercom.help/designrr/en/articles/2391519-how-to-format-a-word-docx">here</a> for more information (opens in new window)',
      tourName: 'importWordTour'
    },
    audio: {
      module: 'mp3',
      image: 'assets/import-sources/from-podcast.svg',
      headingText: 'Import from Podcast',
      subHeadingText: 'Supported file types: MP3, M4A, WAV, AAC, Vimeo or Wistia share link',
      bookNamePlaceholder: 'Title',
      urlPlaceholder: 'Insert link to any audio',
      endPoint: '/import_audio_or_video',
      trackErrorMsg: 'error-uploading-audio-video',
      trackSuccessTag: 'uploaded-audio-video-file',
      creationErrorMsg: 'Error extracting file meta data.',
      downloadType: 'audio',
      processingLoadingMessage: 'Processing audio file',
      transcriptionLanguage: 'en',
      tourName: 'audioPodcastTour'
    },
    flipbook: {
      module: 'flipbook',
      image: 'assets/import-sources/from-pdf.svg',
      headingText: 'PDF to Flipbook',
      bookNamePlaceholder: 'My Flipbook',
      urlPlaceholder: 'Enter the URL of PDF file',
      endPoint: '/create_flipbook_project',
      trackErrorMsg: 'error-importing-pdf-for-flipbook',
      trackSuccessTag: 'imported-pdf-for-flipbook',
      creationErrorMsg: 'Error creating flipbook.',
      downloadType: 'pdf',
      processingLoadingMessage:
        'Generating Flipbook: This may take a few minutes depending on the number of pages and images.',
      buttonText: 'Now I know how to upload my pdf! Next',
      intro:
        'Before uploading your PDF, remove headers & footers, footnotes and table of contents (Designrr can recreate them after import). Then check you have your headings defined. Click <a href="#">here</a> for more information (opens in new window)',
      tourName: 'pdfToFlipbookTour'
    },
    pdf: {
      module: 'pdf',
      image: 'assets/import-sources/from-pdf.svg',
      headingText: 'Import from PDF',
      bookNamePlaceholder: 'My Book',
      urlPlaceholder: 'Enter the URL of PDF file',
      endPoint: '/import_pdf',
      trackErrorMsg: 'error-importing-pdf',
      trackSuccessTag: 'imported-pdf',
      creationErrorMsg: 'Error extracting PDF content.',
      downloadType: 'pdf',
      processingLoadingMessage:
        'Converting PDF: This may take a few minutes depending on the number of pages and images.',
      buttonText: 'Now i know how to upload my pdf! Next',
      intro:
        'Before uploading your PDF, remove headers & footers, footnotes and table of contents (Designrr can recreate them after import). Then check you have your headings defined. Click <a href="#">here</a> for more information (opens in new window)',
      tourName: 'importPdfTour'
    },
    video: {
      module: 'video',
      image: 'assets/import-sources/from-video.svg',
      headingText: 'Import from Video',
      subHeadingText: 'Supported file types: MP4, MOV, YouTube, Vimeo or Wistia share link',
      bookNamePlaceholder: 'Title',
      urlPlaceholder: 'Insert link to any video',
      endPoint: '/import_audio_or_video',
      trackErrorMsg: 'error-uploading-audio-video',
      trackSuccessTag: 'uploaded-audio-video-file',
      creationErrorMsg: 'Error extracting file meta data.',
      downloadType: 'audio',
      processingLoadingMessage: 'Processing audio file',
      transcriptionLanguage: 'en',
      tourName: 'videoTour'
    },
    youtube: {
      module: 'youtube',
      image: 'assets/import-sources/from-youtube.svg',
      headingText: 'Import from YouTube',
      subHeadingText: 'Supported file types: YouTube',
      bookNamePlaceholder: 'Title',
      urlPlaceholder: 'Insert link to any youtube video',
      endPoint: '/import_audio_or_video',
      trackErrorMsg: 'error-uploading-audio-video',
      trackSuccessTag: 'uploaded-audio-video-file',
      creationErrorMsg: 'Error extracting file meta data.',
      downloadType: 'audio',
      processingLoadingMessage: 'Processing youtube video',
      transcriptionLanguage: 'en',
      tourName: 'youtubePodcastTour'
    }
  }
}
