import { defineStore } from 'pinia'
import { http } from '@/helpers/http'
import type { TDraft } from '@/types/drafts'
import type { TProject, TProjectsSection } from '@/types/projects'
import type { TShares } from '@/types/projects'
import type { TProjectFlipbook } from '@/types/flipbooks'
import type { TProjectAudiobook } from '@/types/audiobooks'

export const useFileInfoStore = defineStore('file-info', () => {
  async function getProjectDraft (project: TProject | TProjectAudiobook): Promise<TDraft | null> {
    if (!project?.draft_id) {
      return null
    }

    try {
      const draft: TDraft = await http.post<TDraft>('/get_draft', { id: project.draft_id, getUserEmail: true })
      if (draft) {
        return draft
      }
    } catch(e) {
      return null
    }

    return null
  }

  async function getShares(project: TProject | TDraft | TProjectFlipbook | TProjectAudiobook): Promise<TShares | null> {
    if (!project?.id) {
      return null
    }

    try {
      const shares: TShares | null = await http.get<TShares>('/projects_sections/project_shares/' + project.id)
      if (shares) {
        return shares
      }
    } catch (e) {
      return null
    }

    return null
  }

  async function getProjectFolder(project: TProject): Promise<TProjectsSection | null> {
    try {
      return await http.get<TProjectsSection>('/getProjectFolder/' + project.id)
    } catch {
      return null
    }
  }

  return {
    getProjectDraft,
    getProjectFolder,
    getShares,
  }
})
