<script setup lang="ts">
  import { onMounted, ref } from 'vue'
  import { useResizeObserver } from '@vueuse/core'
  import { columnsCount } from '@/helpers/columnsCount'

  defineProps<{
    twoRows?: boolean
  }>()

  const grid = ref<HTMLElement>()
  const cols = ref<number>(0)

  onMounted(() => cols.value = columnsCount(grid.value))

  useResizeObserver(grid, () => {
    cols.value = columnsCount(grid.value)
  })

  defineExpose({ cols })
</script>

<template>
  <div 
    ref="grid" 
    class="grid gap-6 grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 4xl:grid-cols-7"
    :class="{ 'two-rows': twoRows }"
  >
    <slot></slot>
  </div>
</template>
