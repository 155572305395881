<script setup lang="ts">
  import { useBrowserStore } from '@/stores/browser'
  import { computed } from 'vue'

  const props = defineProps<{
    type?: 'blue' | 'gray' | 'red' | 'lite'
    href?: string
    disabled?: boolean
    click?: () => void
    openInNewTab?: boolean
    submit?: boolean
  }>()

  const { openNewWindowTab } = useBrowserStore()

  function onClick() {
    if (props.disabled) return

    if (props.click) {
      props.click()
    } else if (props.href && !props.openInNewTab) {
      location.href = props.href
    } else if (props.href && props.openInNewTab) {
      openNewWindowTab(props.href)
    }
  }

  const styles = computed(() => ({
    'bg-blue-50 hover:bg-blue-40 border-blue-50 text-white': !props.disabled && (props.type === 'blue' || !props.type),
    'bg-blue-90 hover:bg-blue-90 border-blue-90 text-white': props.disabled && (props.type === 'blue' || !props.type),
    'bg-white hover:bg-black-90 border-black-90 text-blue-10': !props.disabled && props.type === 'gray',
    'bg-white hover:bg-white border-black-90 text-black-80': props.disabled && props.type === 'gray',
    'bg-red-50 hover:bg-red-40 border-red-50 text-white': !props.disabled && props.type === 'red',
    'bg-red-90 hover:bg-red-90 border-red-90 text-white': props.disabled && props.type === 'red',
    'bg-white hover:bg-blue-97 border-blue-50 text-blue-50': !props.disabled && props.type === 'lite',
    'bg-white hover:bg-white border-blue-90 text-blue-90': props.disabled && props.type === 'lite',
    'cursor-not-allowed': props.disabled,
    'cursor-pointer': !props.disabled,
  }))
</script>

<template>
  <button
    v-if="submit"
    class="inline-flex items-center border gap-2 px-5 py-2.5 rounded-lg text-sm font-medium whitespace-nowrap leading-4 select-none transition-colors"
    :class="styles"
    @click="onClick"
  >
    <slot></slot>
  </button>
  <a
    v-else
    class="inline-flex items-center border gap-2 px-5 py-2.5 rounded-lg text-sm font-medium whitespace-nowrap leading-4 select-none transition-colors"
    :class="styles"
    :href="href ?? undefined"
    @click="click && !disabled ? click() : null"
  >
    <slot></slot>
  </a>
</template>
