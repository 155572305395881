import { track } from '@/helpers/mixpanelDes'
import { type Directive, type DirectiveBinding } from 'vue'

export const DesTrack: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    el.addEventListener('click', () => track(binding.value))
  },

  unmounted(el: HTMLElement, binding: DirectiveBinding) {
    el.removeEventListener('click', () => track(binding.value))
  },
}
