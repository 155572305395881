import { track } from '@/helpers/mixpanelDes'
import TimerUtils from '@/helpers/timerUtils'
import HtmlCleaner from "@/helpers/HtmlCleaner";

interface GDocResult {
  source: string
  title: string
  content: string | null
  websiteUrl: string
  domain: string
  thumbnail: string
}

export class GDocImport {
  isGDocUrl(url: string): boolean {
    if (!url) return false
    url = url.trim()
    return url.startsWith('https://docs.google.com')
  }

  handleGDocResHelper(
    html: string | null,
    msg: string = 'Error loading google document',
    url: string,
  ): GDocResult | null {
    if (!html) {
      window.alert(msg) // Using window.alert as a simple replacement for alertify
      return null
    }
    return {
      source: 'url',
      title: '',
      content: html,
      websiteUrl: url,
      domain: '',
      thumbnail: '',
    }
  }

  getDocIdFromUrl(url: string): string {
    if (url.includes('?')) {
      url = url.split('?')[0]
    }
    let id: string | null = null
    if (url.startsWith('https://docs.google.com/document/d/')) {
      id = url.replace('https://docs.google.com/document/d/', '')
      id = id.substring(0, id.indexOf('/edit'))
    } else if (url.startsWith('https://docs.google.com/document/pub?id=')) {
      id = url.replace('https://docs.google.com/document/pub?id=', '')
    } else {
      throw new Error(`Could not handle this Google docs url format: ${url}`)
    }
    id = id.replace('#', '')
    if (id.includes('/')) {
      throw new Error(`Could not detect the gdoc id (unexpected extra /s): ${id}`)
    }
    return id
  }

  getGoogleDocProxyUrl(url: string, baseUrl: string): string {
    const id = this.getDocIdFromUrl(url)
    return `${baseUrl}/google_doc/${id}`
  }

  // getGoogleDocDownloadAsHtml(url: string): string {
  //   const id = this.getDocIdFromUrl(url)
  //   return `https://docs.google.com/document/export?format=html&id=${id}`
  // }

  setupIframe(iframeId: string): HTMLIFrameElement {
    let iframe = document.getElementById(iframeId) as HTMLIFrameElement | null
    if (!iframe) {
      iframe = document.createElement('iframe')
      iframe.id = iframeId
      document.body.appendChild(iframe)
      console.log('Created new iframe')
    }
    return iframe
  }

  async getHtmlFromGDocValidUrl(url: string, baseUrl: string): Promise<{ html: string | null; message?: string }> {
    return new Promise((resolve, reject) => {
      // Wrap the function logic in a new Promise
      const proxyUrl = this.getGoogleDocProxyUrl(url, baseUrl)
      const startAt = new Date()
      const iframe = this.setupIframe('gdoc-iframe')
      iframe.onload = () => {
        try {
          const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document
          if (!iframeDoc) {
            throw new Error('Unable to access iframe document.')
          }
          const body = iframeDoc.body
          const text = body.textContent || ''
          iframe.src = '' // Clear the iframe src
          if (text.startsWith('{"status":"error","message":"')) {
            console.log(text)
            let msg
            try {
              const res = JSON.parse(text)
              msg = res.message
            } catch (e) {
              msg = 'Error parsing response message.'
            }
            const intervalString = TimerUtils.computeAndFormatElapsedTime(startAt)
            track('error-importing-google-document', {
              time: intervalString,
              url: url,
              message: msg,
            })
            resolve({ html: null, message: msg }) // Resolve with null in case of error
          } else {
            // Assuming fullDocCleanup is a method to clean the HTML content
            // This method would need to be adapted to vanilla JS from jQuery.
            this.fullDocCleanup(body)
            const html = body.innerHTML
            const intervalString = TimerUtils.computeAndFormatElapsedTime(startAt)
            if (!html) {
              track('error-importing-google-document', {
                time: intervalString,
                url: url,
              })
            } else {
              track('imported-google-document', {
                time: intervalString,
                url: url,
              })
            }
            resolve({ html }) // Resolve with the HTML content
          }
        } catch (error) {
          const intervalString = TimerUtils.computeAndFormatElapsedTime(startAt)
          track('error-importing-google-document', {
            time: intervalString,
            url: url,
          })
          reject(error) // Reject the promise in case of an exception
        } finally {
          iframe.onload = null // Remove the onload handler to clean up
        }
      }
      iframe.src = proxyUrl // Trigger the loading
    })
  }

  fullDocCleanup(node: HTMLElement): void {
    this.fixImageInsideHeading(node) // ok
    this.inlineTextElementsStyles(node)
    this.unwrapLinksIfNeeded(node) // ok
    this.cleanupLinks(node) // ok
    this.cleanupHeadersAndParagraphs(node) // ok
    HtmlCleaner.cleanupSourceHtml(node);
    HtmlCleaner.handleH1HeadingsIfPresent(node, true);
  }

  fixImageInsideHeading(node: Element): void {
    const images = node.querySelectorAll('h1 img, h2 img, h3 img, h4 img, h5 img, h6 img')
    images.forEach((img) => {
      const heading = img.closest('h1, h2, h3, h4, h5, h6')
      if (heading) {
        heading.parentNode?.insertBefore(img, heading.nextSibling)
      }
    })
  }

  removeUnnecessarySpans(node: Element) {
    const style = node.getAttribute('style')
    node.querySelectorAll('span').forEach((span) => {
      const spanStyle = span.getAttribute('style')?.trim() || ''
      if (spanStyle === '' || style === spanStyle) {
        while (span.firstChild) span.parentNode?.insertBefore(span.firstChild, span)
        span.remove()
      }
    })
  }

  hasJustOneSpan(node: Element, ignoreStyleAndClass: boolean): boolean {
    const children = node.children
    if (children.length !== 1 || node.querySelectorAll('img,a').length > 0) return false
    const child = children[0]
    const style = child.getAttribute('style')?.trim()
    const _class = child.getAttribute('class')?.trim()
    if (child.tagName !== 'SPAN') return false
    if (!ignoreStyleAndClass && (style || _class)) {
      return false
    }
    return this.validateChildNodes(node.childNodes)
  }

  private validateChildNodes(childNodes: NodeListOf<ChildNode>): boolean {
    let count = 0
    let elementCount = 0
    childNodes.forEach((node) => {
      if (node.nodeType === Node.TEXT_NODE && node.nodeValue?.trim()) {
        count++
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        elementCount++
      }
    })
    return count + elementCount <= 1
  }

  // Method to merge similar spans based on their styles
  static mergeSimilarSpans(node: Element): void {
    const spans = Array.from(node.children).filter((child) => child.tagName === 'SPAN')
    if (!spans.length) {
      return
    }
    const styles = spans.map((span) => span.getAttribute('style') || '')
    const matches = this.analyzeArrayElementsEquality(styles)
    const groups = this.groupMatches(matches)
    if (!groups.length) {
      return
    }
    groups.forEach((group) => {
      const intoSpan = spans[group[0]]
      for (let i = 1; i < group.length; i++) {
        const span = spans[group[i]]
        if (!this.areTrueSiblings(intoSpan, span)) {
          break
        }
        intoSpan.innerHTML += span.innerHTML
        console.log('SPAN combined with previous, TEXT was ' + span.textContent)
        span.parentNode?.removeChild(span)
      }
    })
  }

  // Analyzes array elements for equality in styles
  static analyzeArrayElementsEquality(styles: string[]): number[] {
    const matches = new Array(styles.length).fill(0)
    matches[0] = 0
    for (let i = 1; i < styles.length; i++) {
      matches[i] = styles[i] === styles[i - 1] ? matches[i - 1] : i
    }
    return matches
  }

  // Groups matches based on continuous equality
  static groupMatches(matches: number[]): number[][] {
    const results: number[][] = []
    let current: number[] = []
    for (let i = 0; i < matches.length; i++) {
      if (i < matches.length - 1 && matches[i] === matches[i + 1]) {
        if (current.length === 0) {
          current.push(i)
        }
        current.push(i + 1)
      } else {
        if (current.length !== 0) {
          results.push([...current]) // Clone current to ensure we're pushing a new instance
          current = []
        }
      }
    }
    return results
  }

  // Helper function to check if two nodes are true siblings (adjacent)
  private static areTrueSiblings(a: Element, b: Element): boolean {
    return a.nextSibling === b
  }

  cleanupHeadersAndParagraphs(node: Element) {
    // Handling headers and paragraphs
    node.querySelectorAll(':scope > header, :scope > p').forEach((element) => {
      // Placeholder for HtmlCleaner.mergeSimilarSpans as its implementation details are not provided
      GDocImport.mergeSimilarSpans(element)
    })

    node.querySelectorAll(':scope > p, :scope > li').forEach((element) => {
      this.removeUnnecessarySpans(element)
      if (this.hasJustOneSpan(element, true)) {
        GDocImport.mergeSimilarSpans(element)
      }
    })

    function mergeWithChildIfAllowed(node: Element) {
      const span = node.querySelector('span')
      const pStyle = node.getAttribute('style')
      const childStyle = span?.getAttribute('style')
      if (pStyle && pStyle !== childStyle) {
        return
      }
      node.innerHTML = span?.textContent || ''
      if (childStyle) {
        node.setAttribute('style', childStyle)
      }
    }
  }

  _unwrapLinkIfNeeded(a: HTMLAnchorElement): boolean {
    const parent = a.parentElement
    const tagsWhiteList = ['SPAN', 'U', 'B', 'I']
    if (!parent || !tagsWhiteList.includes(parent.tagName.toUpperCase())) {
      return false
    }
    if (parent.childNodes.length === 1 && parent.parentNode) {
      parent.parentNode.insertBefore(a, parent)
      parent.remove()
      return true
    }
    return false
  }

  unwrapLinksIfNeeded(node: Element): void {
    node.querySelectorAll('a').forEach((a) => {
      this._unwrapLinkIfNeeded(a as HTMLAnchorElement)
    })
  }

  cleanupLinks(node: Element): void {
    node.querySelectorAll('a').forEach((a) => {
      const href = a.getAttribute('href')
      if (href && href.startsWith('https://www.google.com/url?q=')) {
        const url = new URL(href)
        const q = url.searchParams.get('q')
        if (q) {
          a.setAttribute('href', q)
        }
      }
    })
  }

  inlineTextElementsStyles(body: Element): void {
    const computedStyle = getComputedStyle(body)

    const defaultBg = computedStyle.backgroundColor
    const bodyAlign = computedStyle.textAlign
    const defaultAligns = [bodyAlign]
    if (bodyAlign === 'start') {
      defaultAligns.push('left')
    }

    const defaultTextStyles: { [key: string]: string[] } = {
      'font-weight': [computedStyle.fontWeight],
      'font-style': ['normal'],
      'text-decoration': ['none', 'rgb(0, 0, 0)'],
      'text-align': defaultAligns,
      'background-color': [defaultBg],
      'color': [computedStyle.color],
    }

    function isCrapDefaultValue(value: string): boolean {
      const crapDefaultStyleValues = ['rgb(0, 0, 0)', 'rgba(0, 0, 0, 0)']
      return crapDefaultStyleValues.includes(value)
    }

    function styleDifferentThanParent(el: Element, style: string, styleVal: string): boolean {
      const parentStyle = getComputedStyle(el.parentElement!)
      return parentStyle.getPropertyValue(style) !== styleVal
    }

    body.querySelectorAll('p, span, li').forEach((el) => {
      Object.keys(defaultTextStyles).forEach((style) => {
        const styleVal = getComputedStyle(el).getPropertyValue(style)
        if (style === 'text-align' && el.getAttribute('dir') === 'rtl') {
          return
        }
        const defaultStyleValArr = defaultTextStyles[style]
        if (
          !defaultStyleValArr.includes(styleVal) &&
          !isCrapDefaultValue(styleVal) &&
          styleDifferentThanParent(el, style, styleVal)
        ) {
          el.setAttribute('style', `${el.getAttribute('style')}; ${style}: ${styleVal}`)
        }
      })
    })
  }
}
