import { ref } from 'vue'
import { defineStore } from 'pinia'

type TPopupConfig =  {
  title: string
  content?: string
  initial?: string
  labelConfirm: string
  labelCancel?: string
  align?: 'center' | 'left'
}

const baseConfig: TPopupConfig = {
  title: '',
  labelConfirm: 'OK',
  labelCancel: 'Cancel',
  initial: '',
  align: 'center',
}

export const usePopupStore = defineStore('popup', () => {
  const config = ref(baseConfig)
  const isOpen = ref(false)
  const resolve = ref<(value: any) => void>()
  const isPrompt = ref(false)

  const handleEscapeKey = (e: KeyboardEvent) => e.key === 'Escape' && close(false)
  const handleEnterKey = (e: KeyboardEvent) => e.key === 'Enter' && !isPrompt.value && close(true)

  async function alert(title: string, options: Partial<TPopupConfig> = {}) {
    return open<boolean>(title, { ...options, labelCancel: undefined, initial: undefined })
  }

  async function confirm(title: string, options: Partial<TPopupConfig> = {}) {
    return open<boolean>(title, { ...options, initial: undefined })
  }

  async function prompt(title: string, options: Partial<TPopupConfig> = {}) {
    if (typeof options.initial === 'undefined') {
      options.initial = ''
    }
    return open<false | string>(title, options, true)
  }

  async function open<T>(title: string, options: Partial<TPopupConfig> = {}, prompt = false) {
    config.value = { ...baseConfig, ...options, title }
    isOpen.value = true
    isPrompt.value = prompt

    document.addEventListener('keydown', handleEscapeKey);
    document.addEventListener('keydown', handleEnterKey);

    return new Promise<T>(res => resolve.value = res)
  }

  function close(result: boolean | string = true) {
    isOpen.value = false
    resolve.value && resolve.value(result)
    document.removeEventListener('keydown', handleEscapeKey);
    document.removeEventListener('keydown', handleEnterKey);
  }

  return { isOpen, isPrompt, config, alert, confirm, prompt, close }
})
