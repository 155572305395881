import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useSnackbarStore } from '@/stores/snackbar'

export const useLoaderStore = defineStore('loader', () => {
  const snack = useSnackbarStore()
  const isVisible = ref<boolean>(false)
  const message = ref<string | undefined>(undefined)
  const id = ref<string | undefined>(undefined)

  function show(msg?: string) {
    isVisible.value = true
    id.value = snack.add(msg ?? 'Loading...', { noClose: true, permanent: true })
  }

  function showCustom(componentToImport: () => Promise<any>) {
    isVisible.value = true
    id.value = snack.add(componentToImport, { noClose: true, permanent: true, custom: true })
  }

  function hide() {
    isVisible.value = false
    id.value && snack.remove(id.value)
  }

  return { isVisible, message, show, showCustom, hide }
})
