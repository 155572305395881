export class DropboxUrlUtil {
  isDropboxShareUrl(url: string): boolean {
    return url.startsWith('https://www.dropbox.com/')
  }

  isDropboxDirectDownloadUrl(url: string): boolean {
    return url.startsWith('https://dl.dropboxusercontent.com/')
  }

  getDirectDownloadUrl(url: string): string {
    if (!this.isDropboxShareUrl(url)) {
      throw new Error('Not a valid or compatible dropbox share url!')
    }
    if (url.includes('?dl=0') && url.includes('&')) {
      url = url.replace('?dl=0', '?dl=1')
    } else if (!(url.includes('?dl=1') && url.includes('&'))) {
      url = url.replace('https://www.dropbox.com/', 'https://dl.dropboxusercontent.com/')
      url = url.replace('?dl=0', '')
    }
    return url
  }
}
