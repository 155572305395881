import { useTooltipStore } from '@/stores/tooltip'
import { type Directive, type DirectiveBinding } from 'vue'

export const DesTooltip: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding) {
    const tip = useTooltipStore()
    const onEnter = () => tip.show(el, binding)
    const onLeave = () => tip.hide()

    el.addEventListener('mouseenter', onEnter)
    el.addEventListener('mouseleave', onLeave)
  },

  unmounted() {
    const tip = useTooltipStore()
    tip.hide()
  },
}
