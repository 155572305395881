import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useRouter } from 'vue-router'

export type TXPosition = 'left' | 'center' | 'right'
export type TYPosition = 'top' | 'center' | 'bottom'
type TItem = { title: string; content: string }
type TPosition = { x: TXPosition; y: TYPosition }

export const useAdviceStore = defineStore('advice', () => {
  const items = ref<TItem[]>([])
  const position = ref<TPosition>({ x: 'right', y: 'top' })
  const visible = ref<boolean>(false)
  const index = ref<number>(0)
  const router = useRouter()
  let handlerHide: ReturnType<typeof setTimeout> | null = null
  let handlerShow: ReturnType<typeof setTimeout> | null = null

  router.beforeEach(() => {
    handlerShow && clearTimeout(handlerShow)
    hide()
  })

  function show(tips: TItem[], x: TXPosition = 'right', y: TYPosition = 'center') {
    handlerHide && clearTimeout(handlerHide)
    handlerShow && clearTimeout(handlerShow)

    items.value = []
    tips.forEach((tip) => items.value.push({ ...tip }))
    position.value = { x, y }
    index.value = 0

    handlerShow = setTimeout(() => (visible.value = true), 1000)
  }

  function hide() {
    handlerHide = setTimeout(() => (items.value = []), 1000)
    visible.value = false
  }

  return { show, hide, items, position, visible, index }
})
