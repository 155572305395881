<script setup lang="ts">
  import { ref } from 'vue'

  type TTextareaProps = {
    label?: string
    disabled?: boolean
    readonly?: boolean
    placeholder?: string
    error?: string
    modelValue: string
    height?: number | string
  }

  defineProps<TTextareaProps>()
  const input = ref<HTMLInputElement | null>(null)

  defineExpose({
    input
  })
</script>

<template>
  <label class="flex-col gap-1 select-none flex w-full">
    <div v-if="label" class="text-black-10 text-sm leading-tight">
      {{ label }}
    </div>
    <div class="relative flex grow">
      <textarea
        :class="{
          'cursor-not-allowed': disabled,
          'border-red-50': error,
          'border-black-90 hover:border-black-60 focus:border-black-60': !error,
        }"
        ref="input"
        :value="modelValue"
        :disabled="disabled"
        :readonly="readonly"
        :placeholder="placeholder"
        @input="!disabled && !readonly && $emit('update:modelValue', input?.value)"
        :rows="height?.toString() ?? '6'"
        class="relative p-3 border text-black-10 text-sm rounded w-full leading-none placeholder-black-60 outline-none bg-transparent transition-colors"
      ></textarea>
      <slot></slot>
    </div>
    <div class="text-red-50 text-xs leading-4" v-if="error">
      {{ error }}
    </div>
  </label>
</template>
