<script setup lang="ts">
  import { usePopupStore } from '@/stores/popup'
  import { onMounted, computed, ref, watch, nextTick } from 'vue'
  import { unrefElement } from '@vueuse/core'
  import DesHeading from '@/components/DesHeading.vue'
  import DesButton from '@/components/button/DesButton.vue'
  import DesModalClose from '@/components/modal/DesModalClose.vue'
  import DesInput from '@/components/form/DesInput.vue'

  const popup = usePopupStore()
  const input = ref<HTMLElement | null>(null)
  const opened = computed(() => popup.isOpen)
  const focus = () => unrefElement(input.value)?.querySelector('input')?.focus()

  onMounted(() => watch(opened, () => nextTick(focus)))
</script>

<template>
  <div
    class="fixed inset-0 z-50 transition-opacity"
    :class="popup.isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'"
  >
    <div class="absolute inset-0 bg-black-10 opacity-25"></div>
    <div
      class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-96 px-8 py-9 bg-white rounded-xl shadow flex flex-col"
      :class="{ 'text-center': popup.config.align === 'center' }"
    >
      <DesModalClose @click="popup.close(false)" />
      <DesHeading level="4">{{ popup.config.title }}</DesHeading>
      <div v-show="popup.isPrompt" class="pt-6">
        <DesInput
          ref="input"
          :label="popup.config.content"
          v-model="popup.config.initial"
          v-if="typeof popup.config.initial !== 'undefined'"
          @keydown.enter="popup.config.initial !== '' && popup.close(popup.config.initial)"
        />
      </div>
      <div v-show="!popup.isPrompt && popup.config.content" class="text-black-40 mt-3">
        {{ popup.config.content }}
      </div>

      <div
        class="flex items-center gap-3 mt-9"
        :class="popup.config.align === 'center' ? 'justify-center' : 'justify-end'"
      >
        <DesButton :click="() => popup.close(false)" type="gray" v-if="popup.config.labelCancel">
          {{ popup.config.labelCancel }}
        </DesButton>

        <DesButton :click="() => popup.close(popup.isPrompt ? popup.config.initial : true)">
          {{ popup.config.labelConfirm }}
        </DesButton>
      </div>
    </div>
  </div>
</template>
