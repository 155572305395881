import { ref, type DirectiveBinding } from 'vue'
import { defineStore } from 'pinia'

export const useTooltipStore = defineStore('tooltip', () => {
  const visible = ref(false)
  const message = ref('')
  const position = ref({ x: 0, y: 0 })
  const direction = ref('top')
  let timeout: NodeJS.Timeout | null = null

  function show(el: HTMLElement, binding: DirectiveBinding) {
    timeout && clearTimeout(timeout)

    if (!binding.value) return

    const rect = el.getBoundingClientRect()
    const dir = binding.arg && ['top', 'right', 'bottom', 'left'].includes(binding.arg) ? binding.arg : 'top'
    const x = dir === 'left' ? rect.x : dir === 'right' ? rect.x + rect.width : rect.x + rect.width / 2
    const y = dir === 'top' ? rect.y : dir === 'bottom' ? rect.y + rect.height : rect.y + rect.height / 2

    message.value = binding.value
    direction.value = dir
    position.value = { x: x + window.scrollX, y: y + window.scrollY }
    visible.value = true
  }

  function hide() {
    timeout = setTimeout(() => {
      visible.value = false
    }, 500)
  }

  function preventHide() {
    timeout && clearTimeout(timeout)
  }

  return { message, visible, direction, position, show, hide, preventHide }
})
