import { defineAsyncComponent, ref, markRaw } from 'vue'
import { defineStore } from 'pinia'
import { usePopupStore } from '@/stores/popup'
import DesSpinner from '@/components/DesSpinner.vue'

type TModal = {
  component: () => Promise<any>
  resolve: (value: any) => void
  init?: { [key: string]: any }
}

export const useModalStore = defineStore('modal', () => {
  const modals = ref<TModal[]>([])

  async function open<T>(componentToImport: () => Promise<any>, init?: { [key: string]: any }) {
    const instance: TModal = {
      component: markRaw(
        defineAsyncComponent({
          loader: componentToImport,
          loadingComponent: DesSpinner,
          onError: async () => {
            const popup = usePopupStore()
            await popup.alert('New version available', {
              labelConfirm: 'Refresh',
              content: 'It seems that there is a new version, please refresh this page.',
            })
            window.location.reload()
          },
        }),
      ),
      init,
      resolve: () => {},
    }

    const promise = new Promise<T>((res) => (instance.resolve = res))
    modals.value.push(instance)
    return promise
  }

  function close(result: any) {
    const instance = modals.value.pop()
    instance?.resolve && instance.resolve(result)
  }

  return { modals, open, close }
})
