import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import { useStorage } from '@vueuse/core'
import { isProduction } from '@/helpers/envCheck'
import type { TUser, TStorageUser, TStorageAccountType } from '@/types/user'
import { usePopupStore } from '@/stores/popup'
import DashboardView from '@/views/agency/client/DashboardView.vue'

const accountType = useStorage('accountType', {} as TStorageAccountType)

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to) {
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    }
    return { top: 0, behavior: 'instant' }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/projects/folders',
      name: 'projects/folders',
      component: () => import('@/views/projects/folders/IndexView.vue'),
    },
    {
      path: '/projects/:type?',
      name: 'projects',
      component: () => import('@/views/projects/IndexView.vue'),
    },
    {
      path: '/projects/folders/:id',
      name: 'projects/folders/folder',
      component: () => import('@/views/projects/folders/FolderView.vue'),
    },
    {
      path: '/media',
      name: 'media/index',
      component: () => import('@/views/media/IndexView.vue'),
    },
    {
      path: '/media/my-uploads',
      name: 'media/my-uploads',
      component: () => import('@/views/media/MyUploadsView.vue'),
    },
    {
      path: '/media/collections',
      name: 'media/collections/index',
      component: () => import('@/views/media/collections/IndexView.vue'),
    },
    {
      path: '/media/collections/:id',
      name: 'media/collections/collection',
      component: () => import('@/views/media/collections/CollectionView.vue'),
    },
    {
      path: '/media/favorites',
      name: 'media-favorites',
      component: () => import('@/views/media/FavoritesView.vue'),
    },
    {
      path: '/creator/:type?/:import?',
      name: 'creator',
      component: () => import('@/views/creator/IndexView.vue'),
    },
    {
      path: '/creator/wordgenie',
      name: 'wordgenie',
      component: () => import('@/views/creator/WordgenieView.vue'),
    },
    {
      path: '/creator/start-from-scratch',
      name: 'start-from-scratch',
      component: () => import('@/views/creator/FromScratchView.vue'),
    },
    {
      path: '/docs/transcription/:id',
      name: 'draft-transcription',
      // component: () => import('@/views/legacy/IndexView.vue'),
      component: () => import('@/views/drafts/edit/TranscriptionView.vue'),
    },
    {
      path: '/docs/audiobook/:id',
      name: 'draft-audiobook',
      component: () =>
        import(isProduction() ? '@/views/legacy/IndexView.vue' : '@/views/drafts/edit/AudiobookView.vue'),
      // component: () => import('@/views/legacy/IndexView.vue'),
      // component: () => import('@/views/drafts/edit/AudiobookView.vue'),
    },
    {
      path: '/docs/ebook/:id',
      name: 'draft-ebook',
      component: () => import('@/views/drafts/edit/EditEBookView.vue'),
    },
    {
      path: '/docs/:type?',
      name: 'drafts',
      component: () => import('@/views/drafts/IndexView.vue'),
    },
    {
      path: '/search/:query?',
      name: 'search',
      component: () => import('@/views/search/IndexView.vue'),
    },
    {
      path: '/questionnaire',
      name: 'questionnaire',
      component: () => import('@/views/questionnaire/IndexView.vue'),
    },
    {
      path: '/templates',
      name: 'templates',
      component: () => import('@/views/templates/IndexView.vue'),
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/profile/IndexView.vue'),
    },
    {
      path: '/agency',
      name: 'agency',
      component: () => import('@/views/agency/IndexView.vue'),
    },
    {
      path: '/team',
      name: 'team',
      component: () => import('@/views/agency/team/IndexView.vue'),
    },
    {
      path: '/team/:id/:slug?',
      name: 'team-member',
      component: () => import('@/views/agency/team/MemberView.vue'),
    },
    {
      path: '/clients',
      name: 'clients',
      component: () => import('@/views/agency/client/IndexView.vue'),
    },
    {
      path: '/clients/:id/:slug?',
      name: 'clients-detail',
      component: () => import('@/views/agency/client/ClientView.vue'),
    },
    {
      path: '/clients/no_access',
      name: 'client_no_access',
      component: () => import('@/views/login/ClientNoAccess.vue'),
    },
    {
      path: '/admin/suggestions',
      name: 'adminSuggestions',
      component: () => import('@/views/admin/SuggestionsView.vue'),
    },
    {
      path: '/admin/variant-niche-images',
      name: 'variantNicheImages',
      component: () => import('@/views/admin/VariantNicheImages.vue'),
    },
    {
      path: '/admin/refund-bot',
      name: 'adminRefundBot',
      component: () => import('@/views/admin/refund-bot/RefundBotView.vue'),
    },
    {
      path: '/admin/refund-bot',
      name: 'adminRefundBot',
      component: () => import('@/views/admin/refund-bot/RefundBotView.vue'),
    },
    {
      path: '/admin/refund-bot/excluded/:id?',
      name: 'adminRefundBotExcludedProducts',
      component: () => import('@/views/admin/refund-bot/RefundBotExcludedView.vue'),
    },
    {
      path: '/admin/refund-bot/group/:id',
      name: 'adminRefundBotProducts',
      component: () => import('@/views/admin/refund-bot/RefundBotProductsView.vue'),
    },
    {
      path: '/admin/ai-prompts',
      name: 'adminRefundBotProducts',
      component: () => import('@/views/admin/ai-prompts/AIPromptsView.vue'),
    },
    {
      path: '/admin/:module?',
      name: 'admin',
      component: () => import('@/views/legacy/IndexView.vue'),
    },
    {
      path: '/ask-for-a-refund/:type',
      name: 'ask-for-a-refund',
      component: () => import('@/views/legacy/IndexView.vue'),
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/LoginView.vue'),
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: () => import('@/views/login/ForgotPasswordView.vue'),
    },
    {
      path: '/reset',
      name: 'reset',
      component: () => import('@/views/login/ResetPasswordView.vue'),
    },
    {
      path: '/request-credentials',
      name: 'request-credentials',
      component: () => import('@/views/login/RequestCredentialsView.vue'),
    },
    {
      path: '/setup_password',
      name: 'setup_password',
      component: () => import('@/views/login/SetupPasswordView.vue'),
    },
    {
      path: '/:catchAll(.*)',
      name: '404',
      component: () => import('@/views/404/IndexView.vue'),
    },
    {
      path: '/client',
      name: 'client_dashboard',
      component: DashboardView,
    },
    {
      path: '/client/account',
      name: 'clients_account',
      component: () => import('@/views/agency/client/ClientSettingsView.vue'),
    },
  ],
})

router.afterEach(() => {
  if (accountType.value?.value?.agencyClient) {
    if (!window.location.href.includes('/dashboard/client')) {
      window.location.href = '/dashboard/login'
    }
  }
})

router.beforeEach(() => {
  const user: TUser = useStorage('user', {} as TStorageUser).value.value
  const allowed = ['/login', '/forgot', '/reset', '/setup_password', 'request-credentials']
  if (!user) {
    const found = allowed.find((r) => window.location.href.includes(r))
    if (!found) {
      window.location.href = '/dashboard/login'
    }
  }
})

router.onError(async () => {
  const popup = usePopupStore()
  const result = await popup.confirm('New version available', {
    labelCancel: 'Cancel',
    labelConfirm: 'Refresh',
    content: 'It seems that there is a new version, please refresh this page.',
  })
  if (!result) {
    return
  }
  window.location.reload()
})

export default router
