const contentFormatter = {
  removeFormatting: function (node: HTMLElement, isTopLevelElement: boolean, isSelected: boolean): void {
    const tag = node.tagName
    const toBeCollapsed = ['B', 'U', 'I', 'EM', 'STRONG', 'S', 'CODE', 'SPAN']
    if (tag === 'A') {
      if (isSelected) {
        contentFormatter.collapseNode(node, isTopLevelElement)
      } else {
        contentFormatter.clearInlineStyles(node)
        node.childNodes.forEach((child) => {
          if (child.nodeType === Node.ELEMENT_NODE) {
            contentFormatter.removeFormatting(child as HTMLElement, false, false)
          }
        })
      }
    } else if (tag === 'P') {
      contentFormatter.clearInlineStyles(node)
      node.childNodes.forEach((child) => {
        if (child.nodeType === Node.ELEMENT_NODE) {
          contentFormatter.removeFormatting(child as HTMLElement, false, false)
        }
      })
    } else if (tag === 'TABLE') {
      node.querySelectorAll('tr').forEach((tr) => {
        contentFormatter.clearDisplayStyles(tr as HTMLElement)
      })

      node.querySelectorAll('td').forEach((td) => {
        contentFormatter.clearDisplayStyles(td as HTMLElement)
      })
      contentFormatter.clearDisplayStyles(node)
    } else if (toBeCollapsed.indexOf(tag) >= 0) {
      contentFormatter.collapseNode(node, isTopLevelElement)
    } else {
      contentFormatter.clearInlineStyles(node)
      const headerTagNames = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6']
      const isHeader = headerTagNames.includes(node.tagName)
      if (isHeader) {
        contentFormatter.flatternElementIfNeeded(node)
      }
    }
  },
  clearInlineStyle: function (node: HTMLElement, style: string): void {
    // prettier-ignore
    // @ts-ignore
    // eslint-disable-next-line
    if (node.style[style]) {
            // prettier-ignore
            // @ts-ignore
            // eslint-disable-next-line
            node.style[style] = '';
        }
  },

  clearDisplayStyles: function (node: HTMLElement): void {
    const stylesToRemove = ['display']
    stylesToRemove.forEach(function (style) {
      contentFormatter.clearInlineStyle(node, style)
    })
  },
  clearInlineStyles: function (node: HTMLElement): void {
    const stylesToRemove = [
      'color',
      'background',
      'font',
      'line-height',
      'letter-spacing',
      'text-decoration',
      'text-align',
      'text-indent',
      'border',
      'box-shadow',
      'text-shadow',
      'transform',
      'margin',
      'padding',
    ]

    stylesToRemove.forEach((style) => contentFormatter.clearInlineStyle(node, style))
  },
  collapseNode: function (node: HTMLElement, isTopLevelElement: boolean): HTMLElement | null {
    const html = node.innerHTML.trim()
    const newNodes = HtmlCleaner.createNodeFromHtml(html)

    if (isTopLevelElement) {
      const needsWrap = Array.from(newNodes).some((child) => child.nodeType === Node.TEXT_NODE)

      if (needsWrap) {
        const newP = HtmlCleaner.createNode('p')
        newNodes.forEach((n) => newP.append(n))
        node.replaceWith(newP)
        return newP
      }
    }
    Array.from(newNodes)
      .reverse()
      .forEach((n) => {
        node.after(n)
      })
    node.remove()
    return null
  },

  flatternElementIfNeeded: function (node: HTMLElement): void {
    if (!node || node.children.length === 0) {
      return
    }
    Array.from(node.querySelectorAll('*'))
      .filter((n) => HtmlCleaner.isEmptyElement(n))
      .forEach((n) => n.remove())
    if (node.children.length !== 1) {
      return
    }

    const anchorCount = node.querySelectorAll('a').length
    if (anchorCount === 0 && node.textContent?.trim() === node.children[0]?.textContent?.trim()) {
      node.innerHTML = node.textContent ?? ''
    }
  },
}

export default class HtmlCleaner {
  static isEmptyElement(n: Element) {
    const trimmedHtml = n.innerHTML.trim()
    return trimmedHtml === '' || trimmedHtml === '&nbsp;'
  }

  static createNode(tag: string, html: string = '', className: string = '') {
    const node = document.createElement(tag)
    node.innerHTML = html
    if (className && className.length > 0) {
      node.setAttribute('class', className)
    }
    return node
  }

  static createNodeFromHtml(html: string): NodeList {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')
    return doc.body.childNodes
  }

  static changeElementTag(node: Element, newTag: string): HTMLElement {
    const prefix = 'string:'
    if (newTag.startsWith(prefix)) {
      newTag = newTag.replace(prefix, '')
    }
    const newNode = HtmlCleaner.createNode(newTag, node.innerHTML, node.className)

    if (node instanceof HTMLElement && node.style.cssText) {
      newNode.setAttribute('style', node.style.cssText)
    }
    node.after(newNode)
    node.remove()
    return newNode
  }

  static changeAllElementsTag(html: HTMLElement, from: string, to: string): void {
    html.querySelectorAll(from).forEach((el) => {
      HtmlCleaner.changeElementTag(el, to)
    })
  }

  static handleH1HeadingsIfPresent(source: HTMLElement, dropTitle: boolean): void {
    const countH1 = source.querySelectorAll('h1').length
    if (countH1 === 0) {
      return
    } else if (countH1 === 1 && dropTitle) {
      const header = source.querySelector('h1, h2, h3, h4, h5, h6')
      if (header?.tagName === 'H1') {
        source.querySelectorAll('h1').forEach((el) => el.remove())
        return
      }
    }
    HtmlCleaner.changeAllElementsTag(source, 'H3', 'H4')
    HtmlCleaner.changeAllElementsTag(source, 'H2', 'H3')
    HtmlCleaner.changeAllElementsTag(source, 'H1', 'H2')
  }

  static cleanupListWithNoChildren(structure: HTMLElement) {
    structure.querySelectorAll('ul, ol').forEach((list) => {
      /** @type {HTMLElement} list **/
      if (list.childElementCount === 0) {
        console.log('Found and removed an empty list')
        console.log(list)
        list.remove()
      }
    })
  }

  static wrap(node: Node, wrapTextWith: string): HTMLElement {
    let wrapped
    if (wrapTextWith.startsWith('<')) {
      wrapped = HtmlCleaner.createNodeFromHtml(wrapTextWith)[0] as HTMLElement
    } else {
      wrapped = HtmlCleaner.createNode(wrapTextWith)
    }
    wrapped.append(node)
    return wrapped
  }

  static unwrapContainer(container: HTMLElement, selector: string, wrapTextWith: string): HTMLElement {
    const div = HtmlCleaner.createNode('div')
    container.querySelectorAll(selector).forEach((c) => {
      const contents = c.childNodes
      if (wrapTextWith) {
        contents.forEach((node) => {
          if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim() !== '') {
            const wrapped = HtmlCleaner.wrap(node, wrapTextWith)
            div.append(wrapped)
          } else {
            div.append(node)
          }
        })
      } else {
        contents.forEach((c) => {
          div.append(c)
        })
      }
    })
    container.after(div)
    container.remove()
    return div
  }

  static unwrapList(list: HTMLElement) {
    HtmlCleaner.unwrapContainer(list, 'LI', '<P />')
  }

  static unwrapNestedTables = function (container: HTMLElement) {
    while (unwrapFirstNestedTable(container)) {
      /* empty */
    }

    function unwrapFirstNestedTable(container: HTMLElement) {
      const nested = Array.from(container.querySelectorAll('table')).filter(isNestedElem)
      if (nested.length === 0) {
        return false
      } else {
        HtmlCleaner.unwrapTable(nested[0])
        return true
      }
    }

    function isNestedElem(elem: HTMLElement): boolean {
      const tag = elem.tagName
      return elem.querySelector(tag) !== null
    }
  }

  static getTextNodeChildren(node: ChildNode): Node[] {
    return Array.from(node.childNodes).filter((c) => {
      return c.nodeType === Node.TEXT_NODE && c.nodeValue?.trim() !== ''
    })
  }

  static wrapTextNodes(node: ChildNode, tag: string) {
    const textNodes = HtmlCleaner.getTextNodeChildren(node)
    textNodes.forEach((c) => {
      HtmlCleaner.wrap(c, tag)
    })
  }

  static simpleUnwrap(el: ChildNode) {
    el.replaceWith(...el.childNodes)
  }

  static hasJustOneSpan(node: HTMLElement, ignoreStyleAndClass: boolean) {
    const children = node.children
    if (children.length !== 1 || node.querySelector('img,a') !== null) return false
    const child = children[0]
    if (child.tagName !== 'SPAN') return false
    const style = child.getAttribute('style') ? child.getAttribute('style')?.trim() : ''
    const _class = child.getAttribute('class') ? child.getAttribute('class')?.trim() : ''

    if (!ignoreStyleAndClass && (style || _class)) {
      return false
    }
    return validateChildNodes(children)

    function validateChildNodes(childNodes: HTMLCollection) {
      let count = 0
      let elementCount = 0
      for (let i = 0; i < childNodes.length; i++) {
        const node = childNodes[i]
        if (node.nodeType === Node.TEXT_NODE) {
          const value = node.nodeValue?.trim()
          if (value) count++
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          elementCount++
        }
        if (count + elementCount > 1) return false
      }
      return true
    }
  }

  static collapseP(p: HTMLElement, ignoreStyleAndClasses: boolean) {
    try {
      if (HtmlCleaner.hasJustOneSpan(p, ignoreStyleAndClasses)) {
        p.innerHTML = p.textContent ?? ''
      }
    } catch (e) {
      console.log(e)
      console.log(p)
    }
  }

  static collapseParagraphs(structure: HTMLElement) {
    const pars = structure.querySelectorAll('p')
    pars.forEach((p) => {
      HtmlCleaner.collapseP(p, false)
    })
  }

  static unwrap(node: HTMLElement) {
    HtmlCleaner.wrapTextNodes(node, 'SPAN')
    unWrapElementJQuery(node, ['BODY', 'HTMLLANG', 'MAIN', 'SECTION', 'DIV', 'ARTICLE', 'HEADER', 'BUTTON'])
    HtmlCleaner.wrapTextNodes(node, 'SPAN')
    wrapInlineTextElements(node, 'P')
    HtmlCleaner.collapseParagraphs(node)

    function wrapInlineTextElements(node: HTMLElement, tag: string) {
      const allTags = ['SPAN', 'A', 'STRONG', 'EM', 'MARK', 'CITE']
      Array.from(node.childNodes)
        .filter((c) => {
          return c instanceof Element && allTags.includes(c.tagName)
        })
        .forEach((c) => {
          HtmlCleaner.wrap(c, tag)
        })
    }

    function unWrapElementJQuery(node: HTMLElement, tags: string[]) {
      tags.forEach(function (tag) {
        node.querySelectorAll(tag).forEach((c) => {
          Array.from(c.children).forEach((cc) => {
            if (cc instanceof HTMLElement) {
              HtmlCleaner.unwrap(cc)
            }
          })
        })
      })
    } //unwrap big containers which include a lot of content and other containers inside
    Array.from(node.children).forEach((child) => {
      //consider only unwrapping content of allowed tags
      const allowedTags = ['DIV', 'SPAN', 'P']
      if (!allowedTags.includes(child.tagName)) {
        return
      }

      const elementsChildren = child.children

      //if some of direct children of element are text nodes, don't unwrap

      const elementsTextDirectContents = Array.from(child.childNodes).filter((c) => {
        return c.nodeType === Node.TEXT_NODE
      })
      const canUnwrap = Array.from(elementsTextDirectContents).some((textNode: Node) => {
        return (textNode.textContent?.trim().length ?? 0) > 0
      })
      if (!canUnwrap) {
        return false
      }

      if (elementsChildren && elementsChildren.length > 1 && child.innerHTML.length > 10000) {
        child.childNodes.forEach((c) => HtmlCleaner.simpleUnwrap(c))
      }
    })
  }

  static unwrapTable(list: HTMLElement) {
    HtmlCleaner.unwrapContainer(list, 'TH,TD', '<P />').querySelector('table')?.remove()
  }

  static isBreakingLineElement(el: HTMLElement) {
    const trimmedText = el.innerText?.trim() ?? ''
    if (trimmedText !== '') {
      return false
    }
    const trimmedHtml = el.innerHTML.trim()
    return trimmedHtml.includes('<br') || trimmedHtml.includes('<BR')
  }

  static transformFontToSpan(temp: HTMLElement): void {
    const sel: string = 'font'
    const fonts = temp.querySelectorAll(sel)
    fonts.forEach((font) => {
      const newNode = HtmlCleaner.changeElementTag(font, 'span')
      const color = font.getAttribute('color')
      if (color) {
        newNode.setAttribute('class', 'font-style')
        newNode.style.color = color
      }
      HtmlCleaner.transformFontToSpan(newNode)
    })
  }

  static containsNonEmptyTextElements(node: HTMLElement): boolean {
    return getNonEmptyTextElements(node).length > 0

    function getNonEmptyTextElements(node: HTMLElement): ChildNode[] {
      return Array.from(node.childNodes).filter((childNode) => {
        return childNode.nodeType === Node.TEXT_NODE && childNode.nodeValue?.trim()
      })
    }
  }

  static transferStyles(sourceElement: HTMLElement, destinationElement: HTMLElement) {
    const sourceStyle = sourceElement.style
    const destinationStyle = destinationElement.style

    for (let i = 0; i < sourceStyle.length; i++) {
      const propertyName = sourceStyle[i]
      const propertyValue = sourceStyle.getPropertyValue(propertyName)
      destinationStyle.setProperty(propertyName, propertyValue)
    }
  }

  static collapseSingleChildWithParent(
    childElem: HTMLElement,
    transferStyles: boolean,
    transferClasses: boolean,
  ): HTMLElement | null {
    const parent = childElem.parentElement
    if (!parent) {
      return null
    }
    if (parent.childElementCount > 1) {
      return null
    }
    if (HtmlCleaner.containsNonEmptyTextElements(parent)) {
      return null
    }
    if (transferStyles) {
      HtmlCleaner.transferStyles(childElem, parent)
    }
    if (transferClasses) {
      childElem.classList.forEach((className) => {
        parent.classList.add(className)
      })
    }
    parent.innerHTML = childElem.innerHTML
    return parent
  }

  static collapseSpanWithParent(nestedSpan: HTMLElement) {
    const res = HtmlCleaner.collapseSingleChildWithParent(nestedSpan, true, true)
    const parentSpan = nestedSpan.parentElement
    if (res !== null) {
      return res
    }

    if (!parentSpan) {
      return null
    }
    if (parentSpan.childNodes.length === 1 || nestedSpan.parentElement.tagName !== 'SPAN') {
      return null
    }

    if (parentSpan.getAttribute('style') !== nestedSpan.getAttribute('style')) {
      return null
    }
    if (parentSpan.getAttribute('class') !== nestedSpan.getAttribute('class')) {
      return null
    }

    const textNode = document.createTextNode(nestedSpan.textContent ?? '')
    nestedSpan.after(textNode)
    nestedSpan.remove()
    return parentSpan
  }

  static collapseNestedSpans(tempDiv: HTMLElement): HTMLElement {
    function collapseSpans(element: HTMLElement) {
      while (element.tagName === 'SPAN' && element.childElementCount === 1 && element.children[0].tagName === 'SPAN') {
        if (!HtmlCleaner.collapseSpanWithParent(element.children[0] as HTMLElement)) {
          break
        }
      }
      const nestedSpans = element.querySelectorAll('span > span')
      nestedSpans.forEach((nestedSpan) => {
        const parentSpan = HtmlCleaner.collapseSpanWithParent(nestedSpan as HTMLElement)
        if (parentSpan) {
          const span = parentSpan?.querySelector('span')
          if (span) {
            collapseSpans(parentSpan)
          }
        }
      })
      return element
    }

    collapseSpans(tempDiv)
    return tempDiv
  }

  static parentTopLevelSpans(div: HTMLElement) {
    const spans = div.querySelectorAll(':scope > span')
    if (spans.length === 0) {
      return
    }

    let p = HtmlCleaner.createNode('p')
    spans[0].before(p)
    spans.forEach((s) => {
      if (p.nextElementSibling === s) {
        p.append(s)
      } else {
        p = HtmlCleaner.createNode('p')
        s.after(p)
        p.append(s)
      }
    })
  }

  static collapseMostUselessNesting(tempDiv: HTMLElement) {
    const candidates = ['li > span,li > p,li > u,i > b,i > i,i > strong']
    candidates.forEach((selector) => {
      const elements = tempDiv.querySelectorAll(selector)

      elements.forEach((el) => {
        HtmlCleaner.collapseSingleChildWithParent(el as HTMLElement, true, true)
      })
    })
  }

  static transformTextStyleElements(temp: HTMLElement): string {
    HtmlCleaner.transformFontToSpan(temp)
    transformToSpanStyle(temp, 'strong , b', 'font-weight', '700')
    transformToSpanStyle(temp, 'em, i', 'font-style', 'italic')
    transformToSpanStyle(temp, 's', 'text-decoration', 'line-through')
    HtmlCleaner.collapseNestedSpans(temp)
    HtmlCleaner.collapseMostUselessNesting(temp)
    HtmlCleaner.collapseMostUselessNesting(temp) //call it 2 times because you can have nesting  like li>p>span
    function transformToSpanStyle(html: HTMLElement, selector: string, style: string, styleValue: string) {
      html.querySelectorAll(selector).forEach((e) => {
        const newNode = HtmlCleaner.changeElementTag(e, 'span')
        newNode.setAttribute('class', 'font-style')
        // prettier-ignore
        // @ts-ignore
        // eslint-disable-next-line
        newNode.style[style] = styleValue
      })
    }

    return temp.innerHTML
  }

  static removeEmptyElements(divElement: HTMLElement, selector: string) {
    const anchorElements = divElement.querySelectorAll(selector)
    anchorElements.forEach((anchor) => {
      const innerText = anchor.innerHTML.trim()
      if (!innerText || innerText === '&nbsp;') {
        anchor.remove()
      }
    })
  }

  static cleanupSourceHtml(structure: HTMLElement) {
    const shouldNotBeEmptySelector: string = `p,span,a`
    HtmlCleaner.removeEmptyElements(structure, shouldNotBeEmptySelector)
    const tagsToRemove = 'head, script, noscript, link, meta, iframe, canvas, input, form, svg, button, style, title'

    structure.querySelectorAll(tagsToRemove).forEach((el) => el.remove())
    structure.querySelectorAll('header > nav').forEach((el) => el?.parentElement?.remove())
    HtmlCleaner.handleH1HeadingsIfPresent(structure, false)
    structure.querySelectorAll('p').forEach((p) => {
      const innerHTML = p.innerHTML
      const empty = ['', '<br>', '<br/>', '<br><br>', '<br>\n<br>']
      if (empty.includes(innerHTML)) {
        p.remove()
      }
    })
    HtmlCleaner.cleanupListWithNoChildren(structure)
    //remove IDs see DES-731
    structure.querySelectorAll('*').forEach((el) => {
      const id = el.getAttribute('id')
      if (!(id?.startsWith('footnote-') || id?.startsWith('endnote-'))) {
        el.removeAttribute('id')
      }
      el.removeAttribute('class')
    })
    //cleanup heading elements DES-453
    structure.querySelectorAll('h1,h2,h3,h4,h5,h6').forEach((el) => {
      contentFormatter.flatternElementIfNeeded(el as HTMLElement)
    })

    fixBrokenSpans(structure, 'P')
    fixBrokenSpans(structure, 'DIV')
    fixWrongParagraphSeparators(structure)
    fixSpanAsBreakLines(structure)
    HtmlCleaner.unwrapNestedTables(structure)
    const tables = findLayoutTables(structure)
    tables.forEach(HtmlCleaner.unwrapTable)
    const lists = findLayoutList(structure)
    lists.forEach((list) => HtmlCleaner.unwrapList(list as HTMLElement))
    structure.querySelectorAll('div,p,a,h1,h2,h3,h4,h5,h6').forEach((el) => {
      if (HtmlCleaner.isEmptyElement(el)) {
        el.remove()
      }
    })
    //remove display style  from P
    structure.querySelectorAll('p').forEach((el) => {
      let style = el.getAttribute('style')
      style = style ? style : ''
      style = style.toLowerCase()

      if (style.indexOf('display') >= 0) {
        el.setAttribute('style', '')
      }
    })

    HtmlCleaner.transformTextStyleElements(structure)
    HtmlCleaner.unwrap(structure)
    removeElementsWithJsCode(structure)

    structure.querySelectorAll('table').forEach(function (table) {
      table.setAttribute('class', 'table')
    })

    HtmlCleaner.removeEmptyElements(structure, shouldNotBeEmptySelector)

    function fixBrokenSpans(structure: HTMLElement, wrongChildTag: string) {
      let counter = 0
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const brokenSpans = Array.from(structure.querySelectorAll('span')).filter((s) => {
          s.querySelector(wrongChildTag)
        })
        if (brokenSpans.length > 0) {
          console.log('found brokenSpans count ' + brokenSpans.length)
          brokenSpans.forEach((s) => {
            HtmlCleaner.wrap(s, 'div')
            s.childNodes.forEach((cc) => {
              HtmlCleaner.simpleUnwrap(cc)
            })
            s.remove()
          })
          counter++
          if (counter > 64) {
            //just in case to prevent infinite loops
            break
          }
        } else {
          break
        }
      }
    }

    function fixWrongParagraphSeparators(structure: HTMLElement) {
      const badSeparators = [/\.<br> *[\r\n]*( *&nbsp;)+ *[\r\n]*( *&nbsp;)*/g]
      const pars = structure.querySelectorAll('p')
      pars.forEach((p) => {
        let html = p.innerHTML
        badSeparators.forEach(function (regEx) {
          html = html.replace(regEx, '314~~~314')
        })
        const parts = html.split('314~~~314')
        if (parts.length > 1) {
          if (parts[0].trim().indexOf('&nbsp;') === 0) {
            parts[0] = parts[0].replace(/ *[\r\n]*( *&nbsp;)+ */g, '')
          }
          parts.reverse().forEach(function (htmlText) {
            const newP = HtmlCleaner.createNode('p', htmlText)
            p.after(newP)
          })
          p.remove()
        }
      })
    }

    function fixSpanAsBreakLines(structure: HTMLElement) {
      const spans = Array.from(structure.querySelectorAll('SPAN')).filter((span) => {
        return HtmlCleaner.isBreakingLineElement(span as HTMLElement)
      })
      if (spans.length === 0) {
        return
      }
      const parent = spans[0].parentElement
      if (!parent) {
        return
      }
      spans.forEach((span) => span.remove())
      parent.querySelectorAll('SPAN').forEach((span) => {
        const p = HtmlCleaner.createNode('p', span.innerHTML)
        span.after(p)
        span.remove()
      })

      Array.from(parent.children).forEach((c) => HtmlCleaner.simpleUnwrap(c))
    }

    function findElementsContainingText(parentElement: HTMLElement, texts: string[]) {
      return Array.from(parentElement.querySelectorAll('*')).filter((e) => {
        return texts.some((text) => e.textContent?.includes(text))
      })
    }

    function removeElementsWithJsCode(structure: HTMLElement) {
      const codeFragments = ['}else{', 'if(', '.html(']
      findElementsContainingText(structure, codeFragments).forEach((e) => e.remove())
    }

    function findLayoutList(source: HTMLElement) {
      return Array.from(source.querySelectorAll('ul, ol')).filter((list) => list.querySelectorAll('li').length === 1)
    }

    function findLayoutTables(source: HTMLElement): HTMLElement[] {
      const tables = Array.from(source.querySelectorAll('table')).filter(
        (table) => isLayoutTable(table) && !table.hasAttribute('data-layout-table'),
      )
      tables.forEach((table) => {
        table.setAttribute('data-layout-table', 'true')
      })
      return tables

      function isLayoutTable(table: HTMLElement): boolean {
        const selectors = ['#blogTable']
        const isLayout = selectors.some((sel) => table.matches(sel))

        if (isLayout) return true

        const tdCount = table.querySelectorAll('td').length
        const trCount = table.querySelectorAll('tr').length
        const nestedTableCount = table.querySelectorAll('table').length

        if (tdCount === 1 || trCount === 1 || nestedTableCount === 1) {
          // A table with one cell, one row, or containing tables must be a layout table
          return true
        }

        // If none of the above conditions are met, it's hopefully a table with data in it
        return false
      }
    }
  }

  static wrapWith(element: ChildNode, tag: string): HTMLElement {
    const wrapper = HtmlCleaner.createNode(tag, '', '')
    element.before(wrapper, element)
    wrapper.appendChild(element)
    return wrapper
  }

  static unwrapDivs(container: HTMLElement): void {
    container.querySelectorAll('DIV').forEach((div) => {
      const contents = div.childNodes
      const nodes: HTMLElement[] = []
      contents.forEach(function (node) {
        if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim() !== '') {
          const p = HtmlCleaner.wrapWith(node, 'p')
          nodes.push(p)
        } else if (node instanceof HTMLElement) {
          nodes.push(node)
        } else {
          console.log('node skipped in unwrapDivs')
          console.log(node)
        }
      })
      nodes.reverse().forEach((n) => {
        div.after(n)
      })
      div.remove()
    })
  }

  static fixNestingElements(parent: HTMLElement): HTMLElement {
    const rootElement = parent
    const filterMethod = (n: ChildNode) => n.nodeType === Node.ELEMENT_NODE || n.nodeType === Node.TEXT_NODE

    while (
      Array.from(parent.childNodes).filter(filterMethod).length === 1 &&
      parent.childNodes[0].nodeType === Node.ELEMENT_NODE
    ) {
      parent = parent.children[0] as HTMLElement
    }

    const clonedNode = parent.cloneNode(true)
    rootElement.insertAdjacentElement('beforebegin', clonedNode as HTMLElement)
    rootElement.remove()

    return clonedNode as HTMLElement
  }

  static complicatedToParagraph(complicated: HTMLElement) {
    const inlineNodeTypes = ['A', 'B', 'U', 'I', 'S', 'SPAN', 'BR', 'STRONG', 'EM', 'SUP', 'SUB', 'CITE', 'MARK']
    const validNode = (e: ChildNode) => e.nodeType === Node.ELEMENT_NODE || e.nodeType === Node.TEXT_NODE
    const hasOnlyTextNode = (e: ChildNode) => e.childNodes.length === 1 && e.childNodes[0].nodeType === Node.TEXT_NODE
    const hasOnlyInlineNodes = (e: HTMLElement) =>
      [...(e.children || [])].every((i) => inlineNodeTypes.indexOf(i.tagName) > -1 || i.nodeType !== Node.ELEMENT_NODE)

    if (hasOnlyTextNode(complicated)) {
      return
    } else if (hasOnlyInlineNodes(complicated)) {
      complicated.textContent = complicated.textContent?.trim() ?? ''
      return
    }

    complicated = HtmlCleaner.fixNestingElements(complicated)

    Array.from(complicated.childNodes).map((e) => {
      if (validNode(e)) {
        if (hasOnlyTextNode(e) || (e instanceof HTMLElement && hasOnlyInlineNodes(e))) {
          const tagName = e.constructor.name === 'HTMLHeadingElement' ? 'h3' : 'p'
          complicated.insertAdjacentHTML('beforebegin', `<${tagName}>${e.textContent}</${tagName}>`)
        } else {
          Array.from(e.childNodes).map((f) => {
            if (validNode(f)) {
              const tagName = f.constructor.name === 'HTMLHeadingElement' ? 'h3' : 'p'
              complicated.insertAdjacentHTML('beforebegin', `<${tagName}>${f.textContent}</${tagName}>`)
            }
          })
        }
      }
    })

    complicated.remove()
  }

  static removeIfEmpty(element: Element) {
    if (element.innerHTML.trim() === '') {
      element.remove()
    }
  }

  static clearAllAttributes(element: Element) {
    while (element.attributes.length > 0) {
      element.removeAttribute(element.attributes[0].name)
    }
  }

  static cleanupHtmlForAudioBook(html: HTMLElement): void {
    // global cleaning
    html.querySelectorAll('img, figure, svg, figcaption, hr, table').forEach((e) => e.remove())
    HtmlCleaner.unwrapDivs(html)
    const doNothingConstructorNames = ['HTMLOListElement', 'HTMLUListElement', 'HTMLDListElement', 'HTMLTableElement']
    // dedicated cleaning
    Array.from(html.children).forEach((e) => {
      if (e.nodeName === 'A' || e.nodeName === 'SPAN' || e.nodeName === 'CITE' || e.nodeName === 'MARK') {
        HtmlCleaner.wrapWith(e, 'p')
      } else if (doNothingConstructorNames.indexOf(e.constructor.name) >= 0) {
        //do nothing
        // builder.tableToParagraph(e);
      } else {
        HtmlCleaner.complicatedToParagraph(e as HTMLElement)
      }
    })

    // finish cleaning
    Array.from(html.children).forEach((e) => {
      HtmlCleaner.removeIfEmpty(e)
      HtmlCleaner.clearAllAttributes(e)
    })
  }
}
