export type TRemoteImportContentFromURL = {
  status: string
  content: string
}

export enum ERemoteImportURLType {
  Web = 'web',
  DocX = 'docx',
  GDoc = 'gdoc',
  Audio = 'audio',
  Flipbook = 'flipbook',
  Pdf = 'pdf',
  Video = 'video',
  YouTube = 'youtube',
}

export enum ERemoteImportURLStatus {
  Idle,
  Fetching,
  Fetched,
  Uploading,
  Uploaded,
  ReadyToTranscribe,
  Processing,
  Error,
}

export type TUploadedFileInfo = {
  awsUrl: string | null
  contentType: string
  desTmpFullPath: string
  desTmpUrl: string
  duration: number
  fileName: string
  import_type: string
  importedFromUrl: string
  mimeType: string
}

export class TRemoteImportURL {
  url: string
  type: ERemoteImportURLType
  status: ERemoteImportURLStatus
  content?: string
  title?: string
  progress?: number
  fileLink?: string
  file?: File
  info?: TUploadedFileInfo
  languageModel = { name: 'en', label: '🇬🇧 English' }

  get language() {
    return this.languageModel?.name
  }

  constructor(type: ERemoteImportURLType, url: string = '', files?: File) {
    this.url = url
    this.file = files
    this.type = type
    this.status = ERemoteImportURLStatus.Idle
    this.progress = 0
  }
}

export type TImportHelper = {
  module: string
  image: string
  headingText: string
  subHeadingText?: string
  bookNamePlaceholder: string
  urlPlaceholder: string
  endPoint: string
  trackErrorMsg: string
  trackSuccessTag: string
  creationErrorMsg: string
  downloadType: string
  processingLoadingMessage: string
  draftName?: string
  buttonText?: string
  intro?: string
  tourName: string
  transcriptionLanguage?: string
}

export type TTranscriptionLegacyEvent = {
  content: string
  source: string
  title: string
  websiteUrl: string
}

export type TImportHelpers = Partial<Record<ERemoteImportURLType, TImportHelper>>
