<script setup lang="ts">
  import { useLoaderStore } from '@/stores/loader'

  const loader = useLoaderStore()
</script>

<template>
  <div
    class="fixed inset-0 z-50 transition-opacity cursor-wait"
    :class="loader.isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'"
  ></div>
</template>
