export class Html2Pdf {
  static resolve(url: string, baseUrl: string): string {
    const doc = document
    const oldBase = doc.getElementsByTagName('base')[0]
    const oldHref = oldBase?.href
    const docHead = doc.head || doc.getElementsByTagName('head')[0]
    const ourBase = oldBase || docHead.appendChild(doc.createElement('base'))
    const resolver = doc.createElement('a')

    ourBase.href = baseUrl || ''
    resolver.href = url
    const resolvedUrl = resolver.href // browser magic at work here

    if (oldBase) {
      oldBase.href = oldHref
    } else {
      docHead.removeChild(ourBase)
    }

    return resolvedUrl
  }

  static fixRelativeUrlsInHtmlText(html: string, base: string): string {
    if (base.endsWith("/")) {
      base = base.substring(0, base.length - 1)
    }

    const parser = new DOMParser()
    const doc = parser.parseFromString(`<div>${html}</div>`, 'text/html')
    const container = doc.body.firstChild as HTMLElement

    container.querySelectorAll('img').forEach(img => {
      let src = img.getAttribute('src')
      if (!src || src.startsWith('data:image')) {
        return
      }
      src = this.resolve(src, base)
      img.setAttribute('src', src)
    })

    container.querySelectorAll('a').forEach(a => {
      let href = a.getAttribute('href')
      if (href) {
        href = this.resolve(href, base)
        a.setAttribute('href', href)
      }
    })

    return container.innerHTML
  }
}