import { defineStore } from 'pinia'
import { usePopupStore } from '@/stores/popup'

export const useBrowserStore = defineStore('browser', () => {

  const popup = usePopupStore()

  async function openNewWindowTab (url: string) {
    let win = window.open(url, '_blank')
    if (!win) {
      const winResult = await popup.confirm('It looks like your browser blocked a popup/new tab for opening.', {
        labelCancel: 'Cancel',
        labelConfirm: 'Open',
      })

      if (winResult !== false) {
        win = window.open(url, '_blank')
        win?.focus()
      }
      return
    }
    win.focus()
  }

  return {
    openNewWindowTab
  }
})
